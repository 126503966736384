import React, {useContext, useEffect, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";

import {useSnackbar} from "notistack";

// Components.
import Page from "../../../components/Page/Page";
import PersonalizedMessage from "../../../components/PersonalizedMessage/PersonalizedMessage";

// Contexts.
import {AppContext} from "../../../contexts/AppContext";

// Material Components.
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

// Material Icons.
import DeleteIcon from "@mui/icons-material/Delete";

// Models.
import {areaToText, stateToText, typeToText} from "../../../models/challenges";

// Utils.
import {apiAdministrationInstance} from "../../../utils/api";
import {showDateTime} from "../../../utils/date";
import {capitalizeFirstLetter} from "../../../utils/strings";

function Delete() {

    // Contexts.
    const appContext = useContext(AppContext);

    // Navigate method.
    const navigate = useNavigate();

    // URL Params.
    const {challengeID} = useParams();

    // Snack.
    const {enqueueSnackbar} = useSnackbar();

    const [challengeData, setChallengeData] = useState({});

    // Execute when change challengeID.
    // Obtains challenge data.
    useEffect(() => {

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        // Call API.
        apiAdministrationInstance.get("/challenges/get?challengeID=" + challengeID).then((response) => {

            setChallengeData(response.data.challenge ?? {});

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

        }).catch((error) => {

            // TODO: handle error.

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));
        });

    }, [challengeID]);

    const handleDelete = () => {

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        apiAdministrationInstance.delete("/challenges/delete?challengeID=" + challengeData.id).then((response) => {

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

            enqueueSnackbar("¡" + capitalizeFirstLetter(response.data.message) + "!", {
                variant: "success",
            });

            // Redirect.
            navigate("../list");

        }).catch((error) => {

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

            if (error.response?.data?.message) {

                enqueueSnackbar("¡" + capitalizeFirstLetter(error.response.data.message) + "!", {
                    variant: "error",
                });

            } else {

                enqueueSnackbar("¡Error interno!", {
                    variant: "error",
                });
            }
        });
    }

    return (
        <Page title="Borrar desafío">
            {
                challengeData.id ?
                    <>
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary="ID"
                                    secondary={challengeData.id}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Tipo"
                                    secondary={typeToText(challengeData.type)}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Área"
                                    secondary={areaToText(challengeData.area)}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Estado"
                                    secondary={stateToText(challengeData.state)}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Última modificación"
                                    secondary={showDateTime(challengeData.last_modification_at)}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Creado"
                                    secondary={showDateTime(challengeData.submit_at)}
                                />
                            </ListItem>
                        </List>

                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            sx={{
                                marginTop: 2,
                            }}
                        >
                            <Grid
                                item
                            >
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    startIcon={<DeleteIcon/>}
                                    onClick={_ => handleDelete()}
                                >
                                    Borrar
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                    :
                    (
                        !appContext.value.loading ?
                            <PersonalizedMessage
                                title="Aish!"
                                secondary="Desafío no encontrado 😔"
                                small
                            />
                            :
                            <></>
                    )
            }
        </Page>
    );
}

export default Delete;