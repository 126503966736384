import React, {useContext, useEffect, useState} from "react";

import {Link} from "react-router-dom";

// Components.
import ActionButton from "../../../components/Buttons/ActionButton";
import Page from "../../../components/Page/Page";

// Contexts.
import {AppContext} from "../../../contexts/AppContext";

// Material Components.
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";

// Material Icons.
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";

// Models.
import {roleToText, stateToText} from "../../../models/users";

// Utils.
import {apiAdministrationInstance} from "../../../utils/api";
import {formatDateTime, relativeDateTime} from "../../../utils/date";

function List() {

    // Contexts.
    const appContext = useContext(AppContext);

    // States.
    const [userList, setUserList] = useState([]);

    // Load userList from API.
    useEffect(() => {

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        // Call API.
        apiAdministrationInstance.get("/users/all_users").then((response) => {

            setUserList(response.data.users ?? []);

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

        }).catch((error) => {

            // TODO: handle error.

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));
        });

    }, []);

    return (
        <Page title="Usuarios">
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{
                    marginBottom: 3,
                }}
            >
                <Grid
                    item
                >
                    <Button
                        variant="contained"
                        startIcon={<AddIcon/>}
                        component={Link}
                        to={"../new"}
                    >
                        Nuevo
                    </Button>
                </Grid>
            </Grid>

            {
                userList.length > 0 ?
                    <TableContainer>
                        <Table sx={{minWidth: 650}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell align="right">Nombre</TableCell>
                                    <TableCell align="right">Apellidos</TableCell>
                                    <TableCell align="right">Correo</TableCell>
                                    <TableCell align="right">Rol</TableCell>
                                    <TableCell align="right">Estado</TableCell>
                                    <TableCell align="right">Registrado</TableCell>
                                    <TableCell align="right">Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    userList.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.id}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.last_name}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.email}
                                            </TableCell>
                                            <TableCell align="right">
                                                {roleToText(row.role)}
                                            </TableCell>
                                            <TableCell align="right">
                                                {stateToText(row.state)}
                                            </TableCell>
                                            <TableCell align="right">
                                                {
                                                    row.registered_at ?
                                                        <Tooltip
                                                            title={formatDateTime(row.registered_at)}
                                                        >
                                                            <p>
                                                                {relativeDateTime(row.registered_at)}
                                                            </p>
                                                        </Tooltip>
                                                        : "-"
                                                }
                                            </TableCell>
                                            <TableCell align="right">
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-end"
                                                    alignItems="flex-start"
                                                    spacing={1}
                                                >
                                                    <Grid
                                                        item
                                                    >
                                                        <ActionButton
                                                            color="primary"
                                                            description="Ver"
                                                            icon={<VisibilityIcon/>}
                                                            component={Link}
                                                            to={"../view/" + row.id}
                                                        />
                                                    </Grid>

                                                    <Grid
                                                        item
                                                    >
                                                        <ActionButton
                                                            color="secondary"
                                                            description="Editar"
                                                            icon={<EditIcon/>}
                                                            component={Link}
                                                            to={"../edit/" + row.id}
                                                        />
                                                    </Grid>

                                                    <Grid
                                                        item
                                                    >
                                                        <ActionButton
                                                            color="secondary"
                                                            description="Borrar"
                                                            icon={<DeleteIcon/>}
                                                            component={Link}
                                                            to={"../delete/" + row.id}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <></>
            }
        </Page>
    );
}

export default List;