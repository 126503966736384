import React, {useContext, useEffect, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";

import {useSnackbar} from "notistack";

// Components.
import Challenge from "../../../../components/Challenge/Challenge";
import Page from "../../../../components/Page/Page";
import PersonalizedMessage from "../../../../components/PersonalizedMessage/PersonalizedMessage";

// Contexts.
import {AppContext} from "../../../../contexts/AppContext";

// Material Components.
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

// Material Icons.
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

// Models.
import {
    defaultChallenge,
    stateAccepted,
    stateInReview,
    stateRejected,
    stateToText,
} from "../../../../models/challenges";

// Utils.
import {apiAdministrationInstance} from "../../../../utils/api";
import {capitalizeFirstLetter} from "../../../../utils/strings";

function Review() {

    // Contexts.
    const appContext = useContext(AppContext);

    // Navigate method.
    const navigate = useNavigate();

    // URL Params.
    const {challengeID, updateIndex} = useParams();

    // Snack.
    const {enqueueSnackbar} = useSnackbar();

    // Form inputs states.
    const [inputUpdate, setInputUpdate] = useState({
        ...defaultChallenge,
        id: "",
    });

    // Execute when change challengeID.
    // Obtains challenge data.
    useEffect(() => {

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        // Call API.
        apiAdministrationInstance.get("/challenges/get_update?challengeID=" + challengeID + "&updateIndex=" + updateIndex).then((response) => {

            setInputUpdate(response.data.update ?? defaultChallenge);

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

        }).catch((error) => {

            // TODO: handle error.

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));
        });

    }, [challengeID]);

    // Clean form inputs.
    // Set form states to default values.
    const cleanInputs = () => {
        setInputUpdate(prevState => ({
            ...prevState,
            ...defaultChallenge,
        }));
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        apiAdministrationInstance.put("/challenges/review_update?challengeID=" + challengeID + "&updateIndex=" + updateIndex, inputUpdate).then((response) => {

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

            enqueueSnackbar("¡" + capitalizeFirstLetter(response.data.message) + "!", {
                variant: "success",
            });

            // Redirect.
            navigate("../list");

        }).catch((error) => {

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

            if (error.response?.data?.message) {

                enqueueSnackbar("¡" + capitalizeFirstLetter(error.response.data.message) + "!", {
                    variant: "error",
                });

            } else {

                enqueueSnackbar("¡Error interno!", {
                    variant: "error",
                });
            }

        });
    }

    return (
        <Page title="Revisar solicitud de actualización">
            {
                inputUpdate.id ?
                    <Box
                        component="form"
                        autoComplete="off"
                        onSubmit={handleSubmit}
                    >
                        <FormControl
                            fullWidth
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            <TextField
                                id="input-id"
                                label="ID"
                                value={challengeID}
                                disabled
                            />
                        </FormControl>

                        <FormControl
                            fullWidth
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            <TextField
                                id="input-update-index"
                                label="Índice"
                                value={updateIndex}
                                disabled
                            />
                        </FormControl>

                        <Challenge
                            state={inputUpdate}
                            setState={setInputUpdate}
                        />

                        <FormControl
                            fullWidth
                            required
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            <InputLabel id="input-state-label">Estado</InputLabel>
                            <Select
                                labelId="input-state-label"
                                id="input-state"
                                label="Estado"
                                value={inputUpdate.state}
                                onChange={event => setInputUpdate(prevState => ({
                                    ...prevState,
                                    state: parseInt(event.target.value),
                                }))}
                            >
                                <MenuItem value={stateInReview}>{stateToText(stateInReview)}</MenuItem>
                                <MenuItem value={stateAccepted}>{stateToText(stateAccepted)}</MenuItem>
                                <MenuItem value={stateRejected}>{stateToText(stateRejected)}</MenuItem>
                            </Select>
                        </FormControl>

                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            sx={{
                                marginTop: 2,
                            }}
                        >
                            <Grid
                                item
                            >
                                <Button
                                    color="secondary"
                                    variant="text"
                                    startIcon={<DeleteIcon/>}
                                    onClick={_ => cleanInputs()}
                                >
                                    Limpiar campos
                                </Button>
                            </Grid>
                            <Grid
                                item
                            >
                                <Button
                                    variant="contained"
                                    type="submit"
                                    startIcon={<SaveIcon/>}
                                >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    :
                    (
                        !appContext.value.loading ?
                            <PersonalizedMessage
                                title="Aish!"
                                secondary="Solicitud de actualización no encontrada 😔"
                                small
                            />
                            :
                            <></>
                    )
            }
        </Page>
    );
}

export default Review;