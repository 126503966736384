import React from "react";

// Components.
import PersonalizedMessage from "../../components/PersonalizedMessage/PersonalizedMessage";

function Unauthorized() {
    return (
        <PersonalizedMessage
            title="Uy!"
            secondary="Error 401, no tienes permisos ‍🤨"
        />
    );
}

export default Unauthorized;