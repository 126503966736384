/*
 * ACTIONS.
 */

// When.
export const whenUnassigned = 0;
export const whenReachesXLevel = 1;
export const whenReachesXFollowers = 2;
export const whenCompletedXQuestions = 3;
export const whenCompletedXChallenges = 4;
export const whenCompletedXStreaks = 5;

export const whenToText = (when) => {
    switch (when) {
        case whenUnassigned:
            return "Sin asignar";

        case whenReachesXLevel:
            return "Alcanza el nivel X";

        case whenReachesXFollowers:
            return "Llega a los X seguidores";

        case whenCompletedXQuestions:
            return "Ha completado X pregunta(s)";

        case whenCompletedXChallenges:
            return "Ha completado X desafíos(s)";

        case whenCompletedXStreaks:
            return "Ha completado una racha de X";

        default:
            return "";
    }
}

// Action.
export const actionUnassigned = 0;
export const actionGive = 1;
export const actionTake = 2;

export const actionToText = (action) => {
    switch (action) {
        case actionUnassigned:
            return "Sin asignar";

        case actionGive:
            return "Dar";

        case actionTake:
            return "Quitar";

        default:
            return "";
    }
}

// Element.
export const elementUnassigned = 0;
export const elementItem = 1;
export const elementAchievements = 2;
export const elementLive = 3;
export const elementCoin = 4;
export const elementExperience = 5;

export const elementToText = (element) => {
    switch (element) {
        case elementUnassigned:
            return "Sin asignar";

        case elementItem:
            return "Ítem";

        case elementAchievements:
            return "Logro";

        case elementLive:
            return "Vida(s)";

        case elementCoin:
            return "Moneda(s)";

        case elementExperience:
            return "Experiencia";

        default:
            return "";
    }
}

// State.
export const stateActive = 0
export const stateDeactivated = 1

export const stateToText = (state) => {
    switch (state) {
        case stateActive:
            return "Activa";

        case stateDeactivated:
            return "Desactivada";

        default:
            return "";
    }
}

// Default Values.
export const defaultAction = {
    "when": 0,
    "when_x_value": 1,
    "when_area": 0,
    "action": 0,
    "element": 0,
    "element_id": "",
    "element_quantity": 1,
    "annotation": "",
};