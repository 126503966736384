import React, {useContext, useEffect, useState} from "react";

import {Link} from "react-router-dom";

// Components.
import ActionButton from "../../../components/Buttons/ActionButton";
import Page from "../../../components/Page/Page";

// Contexts.
import {AppContext} from "../../../contexts/AppContext";

// Material Components.
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";

// Material Icons.
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";

// Utils.
import {apiAdministrationInstance} from "../../../utils/api";
import {formatDateTime, relativeDateTime} from "../../../utils/date";
import {showUserAgent} from "../../../utils/user_agent";

function List() {

    // Contexts.
    const appContext = useContext(AppContext);

    // States.
    const [sessionList, setSessionList] = useState([]);

    // Load sessionList from API.
    useEffect(() => {

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        // Call API.
        apiAdministrationInstance.get("/sessions/all_sessions").then((response) => {

            setSessionList(response.data.sessions ?? []);

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

        }).catch((error) => {

            // TODO: handle error.

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));
        });

    }, []);

    return (
        <Page title="Sesiones">
            {
                sessionList.length > 0 ?
                    <TableContainer>
                        <Table sx={{minWidth: 650}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell align="right">ID Usuario</TableCell>
                                    <TableCell align="right">Emitida</TableCell>
                                    <TableCell align="right">Expira</TableCell>
                                    <TableCell align="right">User Agent</TableCell>
                                    <TableCell align="right">Última Revalidación</TableCell>
                                    <TableCell align="right">Estado</TableCell>
                                    <TableCell align="right">Acciones</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    sessionList.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.id}
                                            </TableCell>

                                            <TableCell align="right">
                                                {row.user_id}
                                            </TableCell>

                                            <TableCell align="right">
                                                {
                                                    row.token_start_date ?
                                                        <Tooltip
                                                            title={formatDateTime(row.token_start_date)}
                                                        >
                                                            <p>
                                                                {relativeDateTime(row.token_start_date)}
                                                            </p>
                                                        </Tooltip>
                                                        : "-"
                                                }
                                            </TableCell>

                                            <TableCell align="right">
                                                {
                                                    row.token_expire_date ?
                                                        <Tooltip
                                                            title={formatDateTime(row.token_expire_date)}
                                                        >
                                                            <p>
                                                                {relativeDateTime(row.token_expire_date)}
                                                            </p>
                                                        </Tooltip>
                                                        : "-"
                                                }
                                            </TableCell>

                                            <TableCell align="right">
                                                {
                                                    row.issued_user_agent ?
                                                        <Tooltip
                                                            title={row.issued_user_agent}
                                                        >
                                                            <p>
                                                                {showUserAgent(row.issued_user_agent)}
                                                            </p>
                                                        </Tooltip>
                                                        :
                                                        "-"
                                                }
                                            </TableCell>

                                            <TableCell align="right">
                                                {
                                                    row.last_revalidation_at ?
                                                        <Tooltip
                                                            title={formatDateTime(row.last_revalidation_at)}
                                                        >
                                                            <p>
                                                                {relativeDateTime(row.last_revalidation_at)}
                                                            </p>
                                                        </Tooltip>
                                                        : "-"
                                                }
                                            </TableCell>

                                            <TableCell align="right">
                                                {row.state ? "Activa" : "Desactivada"}
                                            </TableCell>

                                            <TableCell align="right">
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-end"
                                                    alignItems="flex-start"
                                                    spacing={1}
                                                >
                                                    <Grid
                                                        item
                                                    >
                                                        <ActionButton
                                                            color="primary"
                                                            description="Ver"
                                                            icon={<VisibilityIcon/>}
                                                            component={Link}
                                                            to={"../view/" + row.id}
                                                        />
                                                    </Grid>

                                                    <Grid
                                                        item
                                                    >
                                                        <ActionButton
                                                            color="secondary"
                                                            description="Borrar"
                                                            icon={<DeleteIcon/>}
                                                            component={Link}
                                                            to={"../delete/" + row.id}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <></>
            }
        </Page>
    );
}

export default List;