import React, {useContext, useEffect, useState} from "react";

import {Link, useParams} from "react-router-dom";

// Components.
import Page from "../../../components/Page/Page";
import PersonalizedMessage from "../../../components/PersonalizedMessage/PersonalizedMessage";

// Contexts.
import {AppContext} from "../../../contexts/AppContext";

// Material Components.
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

// Material Icons.
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

// Models.
import {
    rarityToText, recompenseCoin, recompenseExperience, recompenseItem, recompenseLive, recompenseToText,
    stateToText
} from "../../../models/achievements";

// Utils.
import {apiAdministrationInstance} from "../../../utils/api";
import {showDateTime} from "../../../utils/date";

function View() {

    // Contexts.
    const appContext = useContext(AppContext);

    // URL Params.
    const {achievementID} = useParams();

    const [achievementData, setAchievementData] = useState({});

    // Execute when change achievementID.
    // Obtains achievement data.
    useEffect(() => {

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        // Call API.
        apiAdministrationInstance.get("/achievements/get?achievementID=" + achievementID).then((response) => {

            setAchievementData(response.data.achievement ?? {});

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

        }).catch((error) => {

            // TODO: handle error.

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));
        });

    }, [achievementID]);

    return (
        <Page title="Ver logro">
            {
                achievementData.id ?
                    <>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            sx={{
                                marginBottom: 3,
                            }}
                            spacing={1}
                        >
                            <Grid
                                item
                            >
                                <Button
                                    color="primary"
                                    variant="text"
                                    startIcon={<EditIcon/>}
                                    component={Link}
                                    to={"../edit/" + achievementData.id}
                                >
                                    Editar
                                </Button>
                            </Grid>

                            <Grid
                                item
                            >
                                <Button
                                    color="secondary"
                                    variant="text"
                                    startIcon={<DeleteIcon/>}
                                    component={Link}
                                    to={"../delete/" + achievementData.id}
                                >
                                    Borrar
                                </Button>
                            </Grid>
                        </Grid>

                        <List>
                            <ListItem>
                                <ListItemText
                                    primary="ID"
                                    secondary={achievementData.id}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Título"
                                    secondary={achievementData.title}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Rareza"
                                    secondary={rarityToText(achievementData.rarity)}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Recompensa"
                                    secondary={recompenseToText(achievementData.recompense)}
                                />
                            </ListItem>

                            {
                                (achievementData.recompense === recompenseItem) ?
                                    <ListItem>
                                        <ListItemText
                                            primary="ID de la recompensa"
                                            secondary={achievementData.recompense_id}
                                        />
                                    </ListItem>
                                    :
                                    <></>
                            }

                            {
                                (achievementData.recompense === recompenseLive || achievementData.recompense === recompenseCoin || achievementData.recompense === recompenseExperience) ?
                                    <ListItem>
                                        <ListItemText
                                            primary="Cantidad de la recompensa"
                                            secondary={achievementData.recompense_quantity}
                                        />
                                    </ListItem>
                                    :
                                    <></>
                            }

                            <ListItem>
                                <ListItemText
                                    primary="Descripción"
                                    secondary={achievementData.description}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Anotación"
                                    secondary={achievementData.annotation}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Estado"
                                    secondary={stateToText(achievementData.state)}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Última modificación por"
                                    secondary={achievementData.last_modification_by ?? "-"}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Última modificación"
                                    secondary={achievementData.last_modification_at ? showDateTime(achievementData.last_modification_at) : "-"}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Creado por"
                                    secondary={achievementData.submit_by ?? "-"}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Fecha de creación"
                                    secondary={achievementData.submit_at ? showDateTime(achievementData.submit_at) : "-"}
                                />
                            </ListItem>
                        </List>
                    </>
                    :
                    (
                        !appContext.value.loading ?
                            <PersonalizedMessage
                                title="Aish!"
                                secondary="Logro no encontrado 😔"
                                small
                            />
                            :
                            <></>
                    )
            }
        </Page>
    );
}

export default View;