import React from "react";

// Material Components.
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

function ActionButton({color, description, icon, ...props}) {
    return (
        <Tooltip
            title={description}
        >
            <IconButton
                color={color}
                size="small"
                {...props}
            >
                {icon}
            </IconButton>
        </Tooltip>
    );
}

export default ActionButton;