import React, {useContext, useEffect, useState} from "react";

import {Link, useParams} from "react-router-dom";

// Components.
import Page from "../../../components/Page/Page";
import PersonalizedMessage from "../../../components/PersonalizedMessage/PersonalizedMessage";

// Contexts.
import {AppContext} from "../../../contexts/AppContext";

// Material Components.
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

// Material Icons.
import DeleteIcon from "@mui/icons-material/Delete";

// Models.
import {
    actionToText,
    elementAchievements,
    elementCoin,
    elementExperience,
    elementItem,
    elementLive,
    elementToText,
    stateToText,
    whenCompletedXChallenges,
    whenToText
} from "../../../models/actions";
import {areaToText} from "../../../models/challenges";

// Utils.
import {apiAdministrationInstance} from "../../../utils/api";
import {showDateTime} from "../../../utils/date";

function View() {

    // Contexts.
    const appContext = useContext(AppContext);

    // URL Params.
    const {actionID} = useParams();

    const [actionData, setActionData] = useState({});

    // Execute when change actionID.
    // Obtains action data.
    useEffect(() => {

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        // Call API.
        apiAdministrationInstance.get("/actions/get?actionID=" + actionID).then((response) => {

            setActionData(response.data.action ?? {});

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

        }).catch((error) => {

            // TODO: handle error.

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));
        });

    }, [actionID]);

    return (
        <Page title="Ver acción">
            {
                actionData.id ?
                    <>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            sx={{
                                marginBottom: 3,
                            }}
                            spacing={1}
                        >
                            <Grid
                                item
                            >
                                <Button
                                    color="secondary"
                                    variant="text"
                                    startIcon={<DeleteIcon/>}
                                    component={Link}
                                    to={"../delete/" + actionData.id}
                                >
                                    Borrar
                                </Button>
                            </Grid>
                        </Grid>

                        <List>
                            <ListItem>
                                <ListItemText
                                    primary="ID"
                                    secondary={actionData.id}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="¿Cuándo ejecutar?"
                                    secondary={whenToText(actionData.when)}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Valor de X"
                                    secondary={actionData.when_x_value}
                                />
                            </ListItem>

                            {
                                actionData.when === whenCompletedXChallenges ?
                                    <ListItem>
                                        <ListItemText
                                            primary="Área"
                                            secondary={areaToText(actionData.when_area)}
                                        />
                                    </ListItem>
                                    :
                                    <></>
                            }

                            <ListItem>
                                <ListItemText
                                    primary="Acción"
                                    secondary={actionToText(actionData.action)}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Elemento"
                                    secondary={elementToText(actionData.element)}
                                />
                            </ListItem>

                            {
                                (actionData.element === elementItem || actionData.element === elementAchievements) ?
                                    <ListItem>
                                        <ListItemText
                                            primary="ID del elemento"
                                            secondary={actionData.element_id}
                                        />
                                    </ListItem>
                                    :
                                    <></>
                            }

                            {
                                (actionData.element === elementLive || actionData.element === elementCoin || actionData.element === elementExperience) ?
                                    <ListItem>
                                        <ListItemText
                                            primary="Cantidad del elemento"
                                            secondary={actionData.element_quantity}
                                        />
                                    </ListItem>
                                    :
                                    <></>
                            }

                            <ListItem>
                                <ListItemText
                                    primary="Anotación"
                                    secondary={actionData.annotation}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Estado"
                                    secondary={stateToText(actionData.state)}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Subido por"
                                    secondary={actionData.submit_by}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Fecha de subida"
                                    secondary={actionData.submit_at ? showDateTime(actionData.submit_at) : "-"}
                                />
                            </ListItem>
                        </List>
                    </>
                    :
                    (
                        !appContext.value.loading ?
                            <PersonalizedMessage
                                title="Aish!"
                                secondary="Acción no encontrada 😔"
                                small
                            />
                            :
                            <></>
                    )
            }
        </Page>
    );
}

export default View;