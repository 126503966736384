import React, {useContext, useState} from "react";

import {useNavigate} from "react-router-dom";

import {useSnackbar} from "notistack";

// Components.
import Page from "../../../components/Page/Page";

// Contexts.
import {AppContext} from "../../../contexts/AppContext";

// Material Components.
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

// Material Icons.
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

// Models.
import {
    actionGive,
    actionTake,
    actionToText,
    actionUnassigned,
    defaultAction,
    elementAchievements,
    elementCoin,
    elementExperience,
    elementItem,
    elementLive,
    elementToText,
    elementUnassigned,
    whenCompletedXChallenges,
    whenCompletedXQuestions,
    whenCompletedXStreaks,
    whenReachesXFollowers,
    whenReachesXLevel,
    whenToText,
    whenUnassigned
} from "../../../models/actions";
import {areaHistory, areaLanguage, areaMaths, areaScience, areaToText} from "../../../models/challenges";

// Utils.
import {apiAdministrationInstance} from "../../../utils/api";
import {capitalizeFirstLetter} from "../../../utils/strings";

function New() {

    // Contexts.
    const appContext = useContext(AppContext);

    // Navigate method.
    const navigate = useNavigate();

    // Snack.
    const {enqueueSnackbar} = useSnackbar();

    // Form inputs states.
    const [inputAction, setInputAction] = useState(defaultAction);

    // Clean form inputs.
    // Set form states to default values.
    const cleanInputs = () => {
        setInputAction(defaultAction);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        // API Call.
        apiAdministrationInstance.post("/actions/new", inputAction).then((response) => {

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

            // Clean all inputs.
            cleanInputs();

            enqueueSnackbar("¡" + capitalizeFirstLetter(response.data.message) + "!", {
                variant: "success",
            });

            // Redirect.
            navigate("../list");

        }).catch((error) => {

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

            if (error.response?.data?.message) {

                enqueueSnackbar("¡" + capitalizeFirstLetter(error.response.data.message) + "!", {
                    variant: "error",
                });

            } else {

                enqueueSnackbar("¡Error interno!", {
                    variant: "error",
                });
            }
        });
    }

    return (
        <Page title="Nueva acción">
            <Box
                component="form"
                autoComplete="off"
                onSubmit={handleSubmit}
            >
                <FormControl
                    fullWidth
                    required
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <InputLabel id="input-when-label">¿Cuándo ejecutar?</InputLabel>

                    <Select
                        labelId="input-when-label"
                        id="input-when"
                        label="¿Cuándo ejecutar?"
                        value={inputAction.when}
                        onChange={(event) => setInputAction(prevState => ({
                            ...prevState,
                            when: parseInt(event.target.value),
                        }))}
                    >
                        <MenuItem value={whenUnassigned}>
                            {whenToText(whenUnassigned)}
                        </MenuItem>

                        <MenuItem value={whenReachesXLevel}>
                            {whenToText(whenReachesXLevel)}
                        </MenuItem>

                        <MenuItem value={whenReachesXFollowers}>
                            {whenToText(whenReachesXFollowers)}
                        </MenuItem>

                        <MenuItem value={whenCompletedXQuestions}>
                            {whenToText(whenCompletedXQuestions)}
                        </MenuItem>

                        <MenuItem value={whenCompletedXChallenges}>
                            {whenToText(whenCompletedXChallenges)}
                        </MenuItem>

                        <MenuItem value={whenCompletedXStreaks}>
                            {whenToText(whenCompletedXStreaks)}
                        </MenuItem>
                    </Select>
                </FormControl>

                <FormControl
                    fullWidth
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <TextField
                        required
                        id="input-when-x-value"
                        label="Valor de X"
                        type="number"
                        InputProps={{inputProps: {min: 1}}}
                        value={inputAction.when_x_value || inputAction.when_x_value === 0 ? inputAction.when_x_value : ""}
                        onChange={(event) => setInputAction(prevState => ({
                            ...prevState,
                            when_x_value: parseInt(event.target.value),
                        }))}
                    />
                </FormControl>

                {
                    (inputAction.when === whenCompletedXQuestions || inputAction.when === whenCompletedXChallenges || inputAction.when === whenCompletedXStreaks) ?
                        <FormControl
                            fullWidth
                            required
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            <InputLabel id="input-when-area-label">Área</InputLabel>

                            <Select
                                labelId="input-when-area-label"
                                id="input-when-area"
                                label="Área"
                                value={inputAction.when_area}
                                onChange={(event) => setInputAction(prevState => ({
                                    ...prevState,
                                    when_area: parseInt(event.target.value),
                                }))}
                            >
                                <MenuItem value={areaLanguage}>
                                    {areaToText(areaLanguage)}
                                </MenuItem>

                                <MenuItem value={areaMaths}>
                                    {areaToText(areaMaths)}
                                </MenuItem>

                                <MenuItem value={areaScience}>
                                    {areaToText(areaScience)}
                                </MenuItem>

                                <MenuItem value={areaHistory}>
                                    {areaToText(areaHistory)}
                                </MenuItem>
                            </Select>
                        </FormControl>
                        :
                        <></>
                }

                <FormControl
                    fullWidth
                    required
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <InputLabel id="input-action-label">¿Qué hacer?</InputLabel>

                    <Select
                        labelId="input-action-label"
                        id="input-action"
                        label="¿Qué hacer?"
                        value={inputAction.action}
                        onChange={(event) => setInputAction(prevState => ({
                            ...prevState,
                            action: parseInt(event.target.value),
                        }))}
                    >
                        <MenuItem value={actionUnassigned}>
                            {actionToText(actionUnassigned)}
                        </MenuItem>

                        <MenuItem value={actionGive}>
                            {actionToText(actionGive)}
                        </MenuItem>

                        <MenuItem value={actionTake}>
                            {actionToText(actionTake)}
                        </MenuItem>
                    </Select>
                </FormControl>

                <FormControl
                    fullWidth
                    required
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <InputLabel id="input-element-label">Elemento</InputLabel>

                    <Select
                        labelId="input-element-label"
                        id="input-element"
                        label="Elemento"
                        value={inputAction.element}
                        onChange={(event) => setInputAction(prevState => ({
                            ...prevState,
                            element: parseInt(event.target.value),
                        }))}
                    >
                        <MenuItem value={elementUnassigned}>
                            {elementToText(elementUnassigned)}
                        </MenuItem>

                        <MenuItem value={elementItem}>
                            {elementToText(elementItem)}
                        </MenuItem>

                        <MenuItem value={elementAchievements}>
                            {elementToText(elementAchievements)}
                        </MenuItem>

                        <MenuItem value={elementLive}>
                            {elementToText(elementLive)}
                        </MenuItem>

                        <MenuItem value={elementCoin}>
                            {elementToText(elementCoin)}
                        </MenuItem>

                        <MenuItem value={elementExperience}>
                            {elementToText(elementExperience)}
                        </MenuItem>
                    </Select>
                </FormControl>

                {
                    (inputAction.element === elementItem || inputAction.element === elementAchievements) ?
                        <FormControl
                            fullWidth
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            <TextField
                                required
                                id="input-element-id"
                                label="ID del elemento"
                                value={inputAction.element_id}
                                onChange={(event) => setInputAction(prevState => ({
                                    ...prevState,
                                    element_id: event.target.value,
                                }))}
                            />
                        </FormControl>
                        :
                        <></>
                }

                {
                    (inputAction.element === elementLive || inputAction.element === elementCoin || inputAction.element === elementExperience) ?
                        <FormControl
                            fullWidth
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            <TextField
                                required
                                id="input-element-quantity"
                                label="Cantidad del elemento"
                                type="number"
                                InputProps={{inputProps: {min: 1}}}
                                value={inputAction.element_quantity || inputAction.element_quantity === 0 ? inputAction.element_quantity : ""}
                                onChange={(event) => setInputAction(prevState => ({
                                    ...prevState,
                                    element_quantity: parseInt(event.target.value),
                                }))}
                            />
                        </FormControl>
                        :
                        <></>
                }

                <FormControl
                    fullWidth
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <TextField
                        id="input-annotation"
                        label="Anotación"
                        multiline
                        rows={4}
                        value={inputAction.annotation}
                        onChange={(event) => setInputAction(prevState => ({
                            ...prevState,
                            annotation: event.target.value,
                        }))}
                    />
                </FormControl>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    sx={{
                        marginTop: 2,
                    }}
                >
                    <Grid
                        item
                    >
                        <Button
                            color="secondary"
                            variant="text"
                            startIcon={<DeleteIcon/>}
                            onClick={_ => cleanInputs()}
                        >
                            Limpiar campos
                        </Button>
                    </Grid>

                    <Grid
                        item
                    >
                        <Button
                            type="submit"
                            variant="contained"
                            startIcon={<SaveIcon/>}
                        >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Page>
    );
}

export default New;