/*
 * USERS.
 */

// Roles.
export const roleUser = 0;
export const roleSpecialist = 1;
export const roleModerator = 2;
export const roleAdministration = 3;

export const roleToText = (role) => {
    switch (role) {
        case roleUser:
            return "Usuario";

        case roleSpecialist:
            return "Especialista";

        case roleModerator:
            return "Moderador";

        case roleAdministration:
            return "Administrador";

        default:
            return "";
    }
}

// Genders.
export const genderNotAnswer = 0
export const genderMale = 1
export const genderFemale = 2
export const genderOther = 3

export const genderToText = (gender) => {
    switch (gender) {
        case genderNotAnswer:
            return "Prefiero no contestar";

        case genderMale:
            return "Masculino";

        case genderFemale:
            return "Femenino";

        case genderOther:
            return "Otro";

        default:
            return "";
    }
}

// States
export const stateActive = 0
export const stateDeactivated = 1
export const stateBanned = 2

export const stateToText = (gender) => {
    switch (gender) {
        case stateActive:
            return "Cuenta activa";

        case stateDeactivated:
            return "Cuenta desactivada";

        case stateBanned:
            return "Cuenta suspendida";

        default:
            return "";
    }
}