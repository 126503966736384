import React, {useContext, useEffect, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";

import {useSnackbar} from "notistack";

// Components.
import Page from "../../../components/Page/Page";
import PersonalizedMessage from "../../../components/PersonalizedMessage/PersonalizedMessage";

// Contexts.
import {AppContext} from "../../../contexts/AppContext";

// Material Components.
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

// Material Icons.
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

// Models.
import {
    defaultAchievement,
    rarityCommon,
    rarityLegend,
    rarityRare,
    rarityToText,
    recompenseCoin,
    recompenseExperience,
    recompenseItem,
    recompenseLive,
    recompenseToText,
    recompenseUnassigned,
    stateActive,
    stateDeactivated,
    stateToText
} from "../../../models/achievements";

// Utils.
import {apiAdministrationInstance} from "../../../utils/api";
import {capitalizeFirstLetter} from "../../../utils/strings";

function Edit() {

    // Contexts.
    const appContext = useContext(AppContext);

    // Navigate method.
    const navigate = useNavigate();

    // URL Params.
    const {achievementID} = useParams();

    // Snack.
    const {enqueueSnackbar} = useSnackbar();

    // Form inputs states.
    const [inputAchievement, setInputAchievement] = useState({
        ...defaultAchievement,
        id: "",
        state: 0,
    });

    // Execute when change achievementID.
    // Obtains achievement data.
    useEffect(() => {

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        // Call API.
        apiAdministrationInstance.get("/achievements/get?achievementID=" + achievementID).then((response) => {

            setInputAchievement(response.data.achievement ?? {});

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

        }).catch((error) => {

            // TODO: handle error.

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));
        });

    }, [achievementID]);

    // Clean form inputs.
    // Set form states to default values.
    const cleanInputs = () => {
        setInputAchievement(prevState => ({
            ...prevState,
            ...defaultAchievement,
        }));
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        // API Call.
        apiAdministrationInstance.put("/achievements/update", inputAchievement).then((response) => {

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

            // Clean all inputs.
            cleanInputs();

            enqueueSnackbar("¡" + capitalizeFirstLetter(response.data.message) + "!", {
                variant: "success",
            });

            // Redirect.
            navigate("../list");

        }).catch((error) => {

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

            if (error.response?.data?.message) {

                enqueueSnackbar("¡" + capitalizeFirstLetter(error.response.data.message) + "!", {
                    variant: "error",
                });

            } else {

                enqueueSnackbar("¡Error interno!", {
                    variant: "error",
                });
            }
        });
    }

    return (
        <Page title="Editar logro">
            {
                inputAchievement.id ?
                    <Box
                        component="form"
                        autoComplete="off"
                        onSubmit={handleSubmit}
                    >
                        <FormControl
                            fullWidth
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            <TextField
                                id="input-id"
                                label="ID"
                                value={inputAchievement.id}
                                disabled
                            />
                        </FormControl>

                        <FormControl
                            fullWidth
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            <TextField
                                required
                                id="input-title"
                                label="Título"
                                value={inputAchievement.title}
                                onChange={(event) => setInputAchievement(prevState => ({
                                    ...prevState,
                                    title: event.target.value,
                                }))}
                            />
                        </FormControl>

                        <FormControl
                            fullWidth
                            required
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            <InputLabel id="input-rarity-label">Rareza</InputLabel>
                            <Select
                                labelId="input-rarity-label"
                                id="input-rarity"
                                label="Rareza"
                                value={inputAchievement.rarity}
                                onChange={(event) => setInputAchievement(prevState => ({
                                    ...prevState,
                                    rarity: parseInt(event.target.value),
                                }))}
                            >
                                <MenuItem value={rarityCommon}>
                                    {rarityToText(rarityCommon)}
                                </MenuItem>

                                <MenuItem value={rarityRare}>
                                    {rarityToText(rarityRare)}
                                </MenuItem>

                                <MenuItem value={rarityLegend}>
                                    {rarityToText(rarityLegend)}
                                </MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl
                            fullWidth
                            required
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            <InputLabel id="input-recompense-label">Recompensa</InputLabel>

                            <Select
                                labelId="input-recompense-label"
                                id="input-recompense"
                                label="Recompensa"
                                value={inputAchievement.recompense}
                                onChange={(event) => setInputAchievement(prevState => ({
                                    ...prevState,
                                    recompense: parseInt(event.target.value),
                                }))}
                            >
                                <MenuItem value={recompenseUnassigned}>
                                    {recompenseToText(recompenseUnassigned)}
                                </MenuItem>

                                <MenuItem value={recompenseItem}>
                                    {recompenseToText(recompenseItem)}
                                </MenuItem>

                                <MenuItem value={recompenseLive}>
                                    {recompenseToText(recompenseLive)}
                                </MenuItem>

                                <MenuItem value={recompenseCoin}>
                                    {recompenseToText(recompenseCoin)}
                                </MenuItem>

                                <MenuItem value={recompenseExperience}>
                                    {recompenseToText(recompenseExperience)}
                                </MenuItem>
                            </Select>
                        </FormControl>

                        {
                            (inputAchievement.recompense === recompenseItem) ?
                                <FormControl
                                    fullWidth
                                    sx={{
                                        marginBottom: 2
                                    }}
                                >
                                    <TextField
                                        required
                                        id="input-recompense-id"
                                        label="ID de la recompensa"
                                        value={inputAchievement.recompense_id}
                                        onChange={(event) => setInputAchievement(prevState => ({
                                            ...prevState,
                                            recompense_id: event.target.value,
                                        }))}
                                    />
                                </FormControl>
                                :
                                <></>
                        }

                        {
                            (inputAchievement.recompense === recompenseLive || inputAchievement.recompense === recompenseCoin || inputAchievement.recompense === recompenseExperience) ?
                                <FormControl
                                    fullWidth
                                    sx={{
                                        marginBottom: 2
                                    }}
                                >
                                    <TextField
                                        required
                                        id="input-recompense-quantity"
                                        label="Cantidad de la recompensa"
                                        type="number"
                                        InputProps={{inputProps: {min: 1}}}
                                        value={inputAchievement.recompense_quantity || inputAchievement.recompense_quantity === 0 ? inputAchievement.recompense_quantity : ""}
                                        onChange={(event) => setInputAchievement(prevState => ({
                                            ...prevState,
                                            recompense_quantity: parseInt(event.target.value),
                                        }))}
                                    />
                                </FormControl>
                                :
                                <></>
                        }

                        <FormControl
                            fullWidth
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            <TextField
                                id="input-description"
                                label="Descripción"
                                multiline
                                rows={4}
                                value={inputAchievement.description}
                                onChange={(event) => setInputAchievement(prevState => ({
                                    ...prevState,
                                    description: event.target.value,
                                }))}
                            />
                        </FormControl>

                        <FormControl
                            fullWidth
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            <TextField
                                id="input-annotation"
                                label="Anotación"
                                multiline
                                rows={4}
                                value={inputAchievement.annotation}
                                onChange={(event) => setInputAchievement(prevState => ({
                                    ...prevState,
                                    annotation: event.target.value,
                                }))}
                            />
                        </FormControl>

                        <FormControl
                            fullWidth
                            required
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            <InputLabel id="input-state-label">Estado</InputLabel>
                            <Select
                                labelId="input-state-label"
                                id="input-state"
                                label="Estado"
                                value={inputAchievement.state}
                                onChange={event => setInputAchievement(prevState => ({
                                    ...prevState,
                                    state: parseInt(event.target.value),
                                }))}
                            >
                                <MenuItem value={stateActive}>{stateToText(stateActive)}</MenuItem>
                                <MenuItem value={stateDeactivated}>{stateToText(stateDeactivated)}</MenuItem>
                            </Select>
                        </FormControl>

                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            sx={{
                                marginTop: 2,
                            }}
                        >
                            <Grid
                                item
                            >
                                <Button
                                    color="secondary"
                                    variant="text"
                                    startIcon={<DeleteIcon/>}
                                    onClick={_ => cleanInputs()}
                                >
                                    Limpiar campos
                                </Button>
                            </Grid>

                            <Grid
                                item
                            >
                                <Button
                                    type="submit"
                                    variant="contained"
                                    startIcon={<SaveIcon/>}
                                >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    :
                    (
                        !appContext.value.loading ?
                            <PersonalizedMessage
                                title="Aish!"
                                secondary="Logro no encontrado 😔"
                                small
                            />
                            :
                            <></>
                    )
            }
        </Page>
    );
}

export default Edit;