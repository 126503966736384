import React, {useContext, useEffect, useState} from "react";

import {Link, useParams} from "react-router-dom";

// Components.
import Page from "../../../components/Page/Page";
import PersonalizedMessage from "../../../components/PersonalizedMessage/PersonalizedMessage";

// Contexts.
import {AppContext} from "../../../contexts/AppContext";

// Material Components.
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";

// Material Icons.
import DeleteIcon from "@mui/icons-material/Delete";

// Utils.
import {apiAdministrationInstance} from "../../../utils/api";
import {showDateTime} from "../../../utils/date";
import {showUserAgent} from "../../../utils/user_agent";

function View() {

    // Contexts.
    const appContext = useContext(AppContext);

    // URL Params.
    const {sessionID} = useParams();

    const [sessionData, setSessionData] = useState({});

    // Execute when change sessionID.
    // Obtains session data.
    useEffect(() => {

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        // Call API.
        apiAdministrationInstance.get("/sessions/get?sessionID=" + sessionID).then((response) => {

            setSessionData(response.data.session ?? {});

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

        }).catch((error) => {

            // TODO: handle error.

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));
        });

    }, [sessionID]);

    return (
        <Page title="Ver sesión">
            {
                sessionData.id ?
                    <>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            sx={{
                                marginBottom: 3,
                            }}
                            spacing={1}
                        >
                            <Grid
                                item
                            >
                                <Button
                                    color="secondary"
                                    variant="text"
                                    startIcon={<DeleteIcon/>}
                                    component={Link}
                                    to={"../delete/" + sessionData.id}
                                >
                                    Borrar
                                </Button>
                            </Grid>
                        </Grid>

                        <List>
                            <ListItem>
                                <ListItemText
                                    primary="ID"
                                    secondary={sessionData.id}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="ID Usuario solicitante"
                                    secondary={sessionData.user_id}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Emitida el"
                                    secondary={sessionData.token_start_date ? showDateTime(sessionData.token_start_date) : "-"}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Expira el"
                                    secondary={sessionData.token_expire_date ? showDateTime(sessionData.token_expire_date) : "-"}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="User Agent de donde se solicitó"
                                    secondary={
                                        sessionData.issued_user_agent ?
                                            <Tooltip
                                                title={sessionData.issued_user_agent}
                                            >
                                                <span>
                                                    {showUserAgent(sessionData.issued_user_agent)}
                                                </span>
                                            </Tooltip>
                                            :
                                            "-"
                                    }
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="IP de donde se solicitó"
                                    secondary={sessionData.issued_ip ? sessionData.issued_ip : "-"}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="User Agent de la última revalidación"
                                    secondary={
                                        sessionData.last_revalidation_user_agent ?
                                            <Tooltip
                                                title={sessionData.last_revalidation_user_agent}
                                            >
                                                <span>
                                                    {showUserAgent(sessionData.last_revalidation_user_agent)}
                                                </span>
                                            </Tooltip>
                                            :
                                            "-"
                                    }
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="IP de la última revalidación"
                                    secondary={sessionData.last_revalidation_ip ? sessionData.last_revalidation_ip : "-"}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="La última revalidación fue"
                                    secondary={sessionData.last_revalidation_at ? showDateTime(sessionData.last_revalidation_at) : "-"}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Estado"
                                    secondary={sessionData.state ? "Activa" : "Desactivada"}
                                />
                            </ListItem>
                        </List>
                    </>
                    :
                    (
                        !appContext.value.loading ?
                            <PersonalizedMessage
                                title="Aish!"
                                secondary="Sesión no encontrada 😔"
                                small
                            />
                            :
                            <></>
                    )
            }
        </Page>
    );
}

export default View;