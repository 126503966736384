import React, {useState} from "react";

// Material Components.
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

// Material Icons.
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import ImageIcon from "@mui/icons-material/Image";

function Image({state, setState, imageSize, ...props}) {

    // States.
    const [onHover, setOnHover] = useState(false);

    const cleanImage = () => {
        setState(null);
    }

    return (
        <Box
            onMouseEnter={_ => setOnHover(true)}
            onMouseLeave={_ => setOnHover(false)}
            sx={{
                position: "relative"
            }}
        >
            {
                onHover ?
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            position: "absolute",
                            marginTop: "50%",
                            transform: "translate(0, -50%)",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        spacing={1}
                    >
                        <Grid
                            item
                        >
                            <Button
                                variant="text"
                                component="label"
                                startIcon={<FolderIcon/>}
                            >
                                {state ? "Cambiar..." : "Elegir..."}
                                <input
                                    hidden
                                    accept="image/jpg, image/jpeg, image/png"
                                    multiple
                                    type="file"
                                    onChange={event => setState(event.target.files[0])}
                                />
                            </Button>

                        </Grid>

                        {
                            state ?
                                <Grid
                                    item
                                >
                                    <Button
                                        color="secondary"
                                        variant="text"
                                        startIcon={<DeleteIcon/>}
                                        onClick={_ => cleanImage()}
                                    >
                                        Quitar
                                    </Button>
                                </Grid>
                                :
                                <></>
                        }
                    </Grid>
                    :
                    <></>
            }

            <Avatar
                variant="rounded"
                src={state ? URL.createObjectURL(state) : null}
                sx={{
                    width: imageSize,
                    height: imageSize,
                    opacity: onHover ? 0.1 : 1,
                }}
                {...props}
            >
                {
                    !onHover ?
                        <ImageIcon
                            fontSize="large"
                        />
                        :
                        <></>
                }
            </Avatar>
        </Box>
    );
}

export default Image;