import React from "react";

// Material Components.
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Material Icons.
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

// Models.
import {defaultAlternative} from "../../../models/challenges";

/*
 * Three Props Need:
 * - props.state: state of challenges.
 * - props.setState: update state of challenges.
 * - props.questionIndex: correspond to index of the question that has these alternatives.
*/
function Alternatives(props) {

    // Add alternative.
    const addAlternative = (event, questionIndex) => {
        event.preventDefault();

        props.setState(prevState => ({
            ...prevState,
            questions: [
                ...prevState.questions.slice(0, questionIndex),
                {
                    ...prevState.questions[questionIndex],
                    alternatives: [
                        ...prevState.questions[questionIndex].alternatives,
                        defaultAlternative,
                    ],
                },
                ...prevState.questions.slice(questionIndex + 1),
            ]
        }));
    }

    // Remove alternative.
    const removeAlternative = (event, questionIndex, alternativeIndex) => {
        event.preventDefault();

        props.setState(prevState => ({
            ...prevState,
            questions: [
                ...prevState.questions.slice(0, questionIndex),
                {
                    ...prevState.questions[questionIndex],
                    alternatives: prevState.questions[questionIndex].alternatives.filter((_, i) => i !== alternativeIndex),
                },
                ...prevState.questions.slice(questionIndex + 1),
            ]
        }));
    }

    // Update alternative.
    const updateAlternativeField = (event, questionIndex, alternativeIndex, field) => {
        event.preventDefault();

        props.setState(prevState => ({
            ...prevState,
            questions: [
                ...prevState.questions.slice(0, questionIndex),
                {
                    ...prevState.questions[questionIndex],
                    alternatives: [
                        ...prevState.questions[questionIndex].alternatives.slice(0, alternativeIndex),
                        {
                            ...prevState.questions[questionIndex].alternatives[alternativeIndex],
                            ...field,
                        },
                        ...prevState.questions[questionIndex].alternatives.slice(alternativeIndex + 1),
                    ],
                },
                ...prevState.questions.slice(questionIndex + 1),
            ]
        }));
    }

    return (
        <>
            <Grid
                container
                direction="row"
                alignItems="flex-start"
                justifyContent="space-between"
                sx={{
                    marginBottom: 2,
                }}
            >
                <Grid
                    item
                >
                    <Typography
                        variant="subtitle2"
                    >
                        Alternativas
                    </Typography>
                </Grid>

                <Grid
                    item
                >
                    <Button
                        variant="text"
                        startIcon={<AddIcon/>}
                        onClick={event => addAlternative(event, props.questionIndex)}
                    >
                        Añadir alternativa
                    </Button>
                </Grid>
            </Grid>

            {
                // Generate Alternatives inputs.
                props.state.questions[props.questionIndex].alternatives.map((element, index) => (
                    <FormControl
                        fullWidth
                        sx={{
                            marginBottom: 2
                        }}
                        key={index}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid
                                item
                            >
                                <IconButton
                                    color="secondary"
                                    component="label"
                                    onClick={event => removeAlternative(event, props.questionIndex, index)}
                                    disabled={props.state.questions[props.questionIndex].alternatives.length <= 4}
                                >
                                    <DeleteIcon/>
                                </IconButton>
                            </Grid>

                            <Grid
                                item
                                xs
                            >
                                <TextField
                                    fullWidth
                                    required
                                    id={"input-alternative-" + (index + 1)}
                                    label={"Alternativa " + (index + 1)}
                                    value={props.state.questions[props.questionIndex].alternatives[index].answer}
                                    onChange={(event) => updateAlternativeField(event, props.questionIndex, index, {
                                        "answer": event.target.value,
                                    })}
                                />
                            </Grid>

                            <Grid
                                item
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={props.state.questions[props.questionIndex].alternatives[index].is_correct}
                                            onChange={(event) => updateAlternativeField(event, props.questionIndex, index, {
                                                "is_correct": event.target.checked,
                                            })}
                                        />
                                    }
                                    label="¿Correcta?"
                                />
                            </Grid>
                        </Grid>
                    </FormControl>
                ))
            }
        </>
    );
}

export default Alternatives;