/*
 * ACHIEVEMENTS.
 */

// Rarities.
export const rarityCommon = 0;
export const rarityRare = 1;
export const rarityLegend = 2;

export const rarityToText = (rarity) => {
    switch (rarity) {
        case rarityCommon:
            return "Común";

        case rarityRare:
            return "Raro";

        case rarityLegend:
            return "Legendario";

        default:
            return "";
    }
}

// Recompense.
export const recompenseUnassigned = 0;
export const recompenseItem = 1;
export const recompenseLive = 2;
export const recompenseCoin = 3;
export const recompenseExperience = 4;

export const recompenseToText = (recompense) => {
    switch (recompense) {
        case recompenseUnassigned:
            return "Sin asignar";

        case recompenseItem:
            return "Ítem";

        case recompenseLive:
            return "Vida(s)";

        case recompenseCoin:
            return "Moneda(s)";

        case recompenseExperience:
            return "Experiencia";

        default:
            return "";
    }
}

// State.
export const stateActive = 0
export const stateDeactivated = 1

export const stateToText = (state) => {
    switch (state) {
        case stateActive:
            return "Activo";

        case stateDeactivated:
            return "Desactivado";

        default:
            return "";
    }
}

// Default Values.
export const defaultAchievement = {
    "title": "",
    "description": "",
    "rarity": 0,
    "recompense": 0,
    "recompense_id": "",
    "recompense_quantity": 1,
    "annotation": "",
};