/*
 * FILES.
 */

// Types.
export const typeUnassigned = 0;
export const typeImage = 1;
export const typeDocument = 2;

export const typeToText = (type) => {
    switch (type) {
        case typeUnassigned:
            return "Sin asignar";

        case typeImage:
            return "Imagen";

        case typeDocument:
            return "Documento";

        default:
            return "";
    }
}