import {UAParser} from "ua-parser-js";

// parserUserAgent parse a raw userAgent and convert it into an object.
const parserUserAgent = (userAgent) => {

    const parser = new UAParser();

    parser.setUA(userAgent);

    return parser.getResult();
}

// showUserAgent parse rawUser agent and return a simple string.
export const showUserAgent = (userAgent) => {

    // Parse userAgent data.
    const parsed = parserUserAgent(userAgent)

    // Organize information from parsed data.
    const browserInfo = [parsed.browser.name, parsed.browser.version].join(" ").trim()
    const osInfo = [parsed.os.name, parsed.os.version].join(" ").trim()

    // If userAgent has no "util" data, return without changes.
    if (browserInfo === "" && osInfo === "") {
        return userAgent;
    }

    return [browserInfo, osInfo].join(" - ");
}