import React, {useContext, useEffect, useState} from "react";

import {useNavigate} from "react-router-dom";

import {useSnackbar} from "notistack";

import {clean, format, validate} from "rut.js";

// Components.
import Page from "../../../components/Page/Page";

// Contexts.
import {AppContext} from "../../../contexts/AppContext";

// Material Components.
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";

// Material Date.
import {DesktopDatePicker} from "@mui/x-date-pickers";

// Material Icons.
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

// Models.
import {
    genderFemale,
    genderMale,
    genderNotAnswer,
    genderOther,
    genderToText,
    roleAdministration,
    roleModerator,
    roleSpecialist,
    roleToText,
    roleUser,
} from "../../../models/users";

// Utils.
import {apiAdministrationInstance} from "../../../utils/api";
import {capitalizeFirstLetter} from "../../../utils/strings";

function New() {

    // Contexts.
    const appContext = useContext(AppContext);

    // Navigate method.
    const navigate = useNavigate();

    // Snack.
    const {enqueueSnackbar} = useSnackbar();

    // Form inputs states.
    const [inputUsername, setInputUsername] = useState("");
    const [inputName, setInputName] = useState("");
    const [inputLastname, setInputLastname] = useState("");
    const [inputRUT, setInputRUT] = useState("");
    const [inputEmail, setInputEmail] = useState("");
    const [inputPhone, setInputPhone] = useState(null);
    const [inputBirthday, setInputBirthday] = useState(null);
    const [inputGender, setInputGender] = useState(0);
    const [inputRole, setInputRole] = useState(0);
    const [inputPassword, setInputPassword] = useState("");
    const [inputConfirmPassword, setInputConfirmPassword] = useState("");

    // Error states.
    const [errorRUT, setErrorRUT] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);

    // Validate inputRUT.
    useEffect(() => {

        if (inputRUT !== "" && !validate(inputRUT)) {
            setErrorRUT(true);
            return;
        }

        setErrorRUT(false);
    }, [inputRUT]);

    // setInputRUT, but with rut format.
    const setInputRUTFormatted = (input) => {

        if (input !== "") {
            setInputRUT(format(input));
            return
        }

        setInputRUT(input);
    }

    // Validate passwords.
    useEffect(() => {

        if ((inputPassword !== "" || inputConfirmPassword !== "") && (inputPassword !== inputConfirmPassword)) {
            setErrorPassword(true);
            return;
        }

        setErrorPassword(false);
    }, [inputPassword, inputConfirmPassword]);

    // Clean form inputs.
    // Set form states to default values.
    const cleanInputs = () => {
        setInputUsername("");
        setInputName("");
        setInputLastname("");
        setInputRUT("");
        setInputEmail("");
        setInputPhone(null);
        setInputBirthday(null);
        setInputGender(0);
        setInputRole(0);
        setInputPassword("");
        setInputConfirmPassword("");
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        // Validate passwords.
        if (inputPassword !== inputConfirmPassword) {

            enqueueSnackbar("¡Las contraseñas no coinciden!", {
                variant: "error",
            });

            return
        }

        // Validate RUT.
        if (errorRUT) {

            enqueueSnackbar("¡RUT inválido!", {
                variant: "error",
            });

            return
        }

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        apiAdministrationInstance.post("/users/new", {
            "username": inputUsername,
            "name": inputName,
            "last_name": inputLastname,
            "rut": clean(inputRUT),
            "email": inputEmail,
            "phone": inputPhone,
            "birthday": inputBirthday,
            "gender": inputGender,
            "role": inputRole,
            "password": inputPassword,
        }).then((response) => {

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

            // Clean all inputs.
            cleanInputs();

            enqueueSnackbar("¡" + capitalizeFirstLetter(response.data.message) + "!", {
                variant: "success",
            });

            // Redirect.
            navigate("../list");

        }).catch((error) => {

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

            if (error.response?.data?.message) {

                enqueueSnackbar("¡" + capitalizeFirstLetter(error.response.data.message) + "!", {
                    variant: "error",
                });

            } else {

                enqueueSnackbar("¡Error interno!", {
                    variant: "error",
                });
            }

        });
    }

    return (
        <Page title="Nuevo usuario">
            <Box
                component="form"
                autoComplete="off"
                onSubmit={handleSubmit}
            >
                <FormControl
                    fullWidth
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <TextField
                        required
                        id="input-username"
                        label="Nombre de usuario"
                        value={inputUsername}
                        onChange={(event) => setInputUsername(event.target.value)}
                    />
                </FormControl>

                <FormControl
                    fullWidth
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <TextField
                        required
                        id="input-name"
                        label="Nombre"
                        value={inputName}
                        onChange={(event) => setInputName(event.target.value)}
                    />
                </FormControl>

                <FormControl
                    fullWidth
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <TextField
                        required
                        id="input-lastname"
                        label="Apellidos"
                        value={inputLastname}
                        onChange={(event) => setInputLastname(event.target.value)}
                    />
                </FormControl>

                <FormControl
                    fullWidth
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <TextField
                        id="input-rut"
                        label="RUT"
                        error={errorRUT}
                        value={inputRUT}
                        onChange={(event) => setInputRUTFormatted(event.target.value)}
                    />
                </FormControl>

                <FormControl
                    fullWidth
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <TextField
                        required
                        id="input-email"
                        label="Correo electrónico"
                        type="email"
                        value={inputEmail}
                        onChange={(event) => setInputEmail(event.target.value)}
                    />
                </FormControl>

                <FormControl
                    fullWidth
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <TextField
                        id="input-phone"
                        label="Celular"
                        type="tel"
                        value={inputPhone || inputPhone === 0 ? inputPhone : ""}
                        onChange={(event) => setInputPhone(parseInt(event.target.value))}
                    />
                </FormControl>

                <FormControl
                    fullWidth
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <DesktopDatePicker
                        label="Fecha de nacimiento"
                        inputFormat="dd/MM/yyyy"
                        value={inputBirthday}
                        onChange={value => setInputBirthday(value)}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </FormControl>

                <FormControl
                    fullWidth
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <InputLabel id="input-gender-label">Género</InputLabel>
                    <Select
                        labelId="input-gender-label"
                        id="input-gender"
                        label="Género"
                        value={inputGender}
                        onChange={(event) => setInputGender(parseInt(event.target.value))}
                    >
                        <MenuItem value={genderNotAnswer}>{genderToText(genderNotAnswer)}</MenuItem>
                        <MenuItem value={genderMale}>{genderToText(genderMale)}</MenuItem>
                        <MenuItem value={genderFemale}>{genderToText(genderFemale)}</MenuItem>
                        <MenuItem value={genderOther}>{genderToText(genderOther)}</MenuItem>
                    </Select>
                </FormControl>

                <FormControl
                    fullWidth
                    required
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <InputLabel id="input-role-label">Rol</InputLabel>
                    <Select
                        labelId="input-role-label"
                        id="input-role"
                        label="Rol"
                        value={inputRole}
                        onChange={(event) => setInputRole(parseInt(event.target.value))}
                    >
                        <MenuItem value={roleUser}>{roleToText(roleUser)}</MenuItem>
                        <MenuItem value={roleSpecialist}>{roleToText(roleSpecialist)}</MenuItem>
                        <MenuItem value={roleModerator}>{roleToText(roleModerator)}</MenuItem>
                        <MenuItem value={roleAdministration}>{roleToText(roleAdministration)}</MenuItem>
                    </Select>
                </FormControl>

                <FormControl
                    fullWidth
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <TextField
                        required
                        id="input-password"
                        label="Contraseña"
                        type="password"
                        error={errorPassword}
                        value={inputPassword}
                        onChange={(event) => setInputPassword(event.target.value)}
                    />
                </FormControl>

                <FormControl
                    fullWidth
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <TextField
                        required
                        id="input-confirm-password"
                        label="Confirmar contraseña"
                        type="password"
                        error={errorPassword}
                        value={inputConfirmPassword}
                        onChange={(event) => setInputConfirmPassword(event.target.value)}
                    />
                </FormControl>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    sx={{
                        marginTop: 2,
                    }}
                >
                    <Grid
                        item
                    >
                        <Button
                            color="secondary"
                            variant="text"
                            startIcon={<DeleteIcon/>}
                            onClick={_ => cleanInputs()}
                        >
                            Limpiar campos
                        </Button>
                    </Grid>

                    <Grid
                        item
                    >
                        <Button
                            variant="contained"
                            type="submit"
                            startIcon={<SaveIcon/>}
                        >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Page>
    );
}

export default New;