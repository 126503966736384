import React, {useContext, useState} from "react";

import {useNavigate} from "react-router-dom";

import {useSnackbar} from "notistack";

// Components.
import Image from "../../../components/Image/Image";
import Page from "../../../components/Page/Page";

// Contexts.
import {AppContext} from "../../../contexts/AppContext";

// Material Components.
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

// Material Icons.
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

// Models.
import {
    defaultItem,
    rarityCommon,
    rarityLegend,
    rarityRare,
    rarityToText,
    typeAvatar,
    typeAvatarVariant,
    typeProfileFrame,
    typeProfilePicture,
    typeTitle,
    typeToText,
    typeUnassigned
} from "../../../models/items";

// Utils.
import {apiAdministrationInstance} from "../../../utils/api";
import {capitalizeFirstLetter} from "../../../utils/strings";

function New() {

    // Contexts.
    const appContext = useContext(AppContext);

    // Navigate method.
    const navigate = useNavigate();

    // Snack.
    const {enqueueSnackbar} = useSnackbar();

    // Form inputs states.
    const [inputItem, setInputItem] = useState(defaultItem);
    const [inputImage, setInputImage] = useState(null);

    // Clean form inputs.
    // Set form states to default values.
    const cleanInputs = () => {
        setInputItem(defaultItem);
        setInputImage(null);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        // Define form data.
        const formData = new FormData();

        // Add file to form data.
        formData.append("image_file", inputImage);

        // File data.
        const jsonData = JSON.stringify(inputItem);

        formData.append("data", jsonData);

        // API Call.
        apiAdministrationInstance.post("/items/new", formData).then((response) => {

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

            // Clean all inputs.
            cleanInputs();

            enqueueSnackbar("¡" + capitalizeFirstLetter(response.data.message) + "!", {
                variant: "success",
            });

            // Redirect.
            navigate("../list");

        }).catch((error) => {

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

            if (error.response?.data?.message) {

                enqueueSnackbar("¡" + capitalizeFirstLetter(error.response.data.message) + "!", {
                    variant: "error",
                });

            } else {

                enqueueSnackbar("¡Error interno!", {
                    variant: "error",
                });
            }
        });
    }

    return (
        <Page title="Nuevo ítem">
            <Box
                component="form"
                autoComplete="off"
                onSubmit={handleSubmit}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{
                        marginBottom: 2,
                    }}
                    spacing={3}
                >
                    <Grid
                        item
                    >
                        <Image
                            state={inputImage}
                            setState={setInputImage}
                            imageSize={272}
                        />
                    </Grid>

                    <Grid
                        item
                        xs
                    >
                        <FormControl
                            fullWidth
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            <TextField
                                required
                                id="input-title"
                                label="Título"
                                value={inputItem.title}
                                onChange={(event) => setInputItem(prevState => ({
                                    ...prevState,
                                    title: event.target.value,
                                }))}
                            />
                        </FormControl>

                        <FormControl
                            fullWidth
                            required
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            <InputLabel id="input-type-label">Tipo</InputLabel>
                            <Select
                                labelId="input-type-label"
                                id="input-type"
                                label="Tipo"
                                value={inputItem.type}
                                onChange={(event) => setInputItem(prevState => ({
                                    ...prevState,
                                    type: parseInt(event.target.value),
                                }))}
                            >
                                <MenuItem value={typeUnassigned}>
                                    {typeToText(typeUnassigned)}
                                </MenuItem>

                                <MenuItem value={typeTitle}>
                                    {typeToText(typeTitle)}
                                </MenuItem>

                                <MenuItem value={typeProfileFrame}>
                                    {typeToText(typeProfileFrame)}
                                </MenuItem>

                                <MenuItem value={typeProfilePicture}>
                                    {typeToText(typeProfilePicture)}
                                </MenuItem>

                                <MenuItem value={typeAvatar}>
                                    {typeToText(typeAvatar)}
                                </MenuItem>

                                <MenuItem value={typeAvatarVariant}>
                                    {typeToText(typeAvatarVariant)}
                                </MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl
                            fullWidth
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            <TextField
                                disabled={inputItem.type !== typeAvatarVariant}
                                required={inputItem.type === typeAvatarVariant}
                                id="input-avatar-main-id"
                                label="ID avatar principal"
                                value={inputItem.avatar_main_id}
                                onChange={(event) => setInputItem(prevState => ({
                                    ...prevState,
                                    avatar_main_id: event.target.value,
                                }))}
                            />
                        </FormControl>

                        <FormControl
                            fullWidth
                            required
                        >
                            <InputLabel id="input-rarity-label">Rareza</InputLabel>

                            <Select
                                labelId="input-rarity-label"
                                id="input-rarity"
                                label="Rareza"
                                value={inputItem.rarity}
                                onChange={(event) => setInputItem(prevState => ({
                                    ...prevState,
                                    rarity: parseInt(event.target.value),
                                }))}
                            >
                                <MenuItem value={rarityCommon}>
                                    {rarityToText(rarityCommon)}
                                </MenuItem>

                                <MenuItem value={rarityRare}>
                                    {rarityToText(rarityRare)}
                                </MenuItem>

                                <MenuItem value={rarityLegend}>
                                    {rarityToText(rarityLegend)}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <FormControlLabel
                    sx={{
                        marginBottom: 2
                    }}
                    control={
                        <Checkbox
                            checked={inputItem.is_merchantable}
                            onChange={(event) => setInputItem(prevState => ({
                                ...prevState,
                                is_merchantable: event.target.checked,
                            }))}
                        />
                    }
                    label="¿Comerciable en la tienda?"
                />

                <FormControl
                    fullWidth
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <TextField
                        id="input-price"
                        label="Precio"
                        type="number"
                        InputProps={{inputProps: {min: 0}}}
                        disabled={!inputItem.is_merchantable}
                        value={inputItem.price || inputItem.price === 0 ? inputItem.price : ""}
                        onChange={(event) => setInputItem(prevState => ({
                            ...prevState,
                            price: parseInt(event.target.value),
                        }))}
                    />
                </FormControl>

                <FormControl
                    fullWidth
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <TextField
                        id="input-annotation"
                        label="Anotación"
                        multiline
                        rows={4}
                        value={inputItem.annotation}
                        onChange={(event) => setInputItem(prevState => ({
                            ...prevState,
                            annotation: event.target.value,
                        }))}
                    />
                </FormControl>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    sx={{
                        marginTop: 2,
                    }}
                >
                    <Grid
                        item
                    >
                        <Button
                            color="secondary"
                            variant="text"
                            startIcon={<DeleteIcon/>}
                            onClick={_ => cleanInputs()}
                        >
                            Limpiar campos
                        </Button>
                    </Grid>

                    <Grid
                        item
                    >
                        <Button
                            type="submit"
                            variant="contained"
                            startIcon={<SaveIcon/>}
                        >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Page>
    );
}

export default New;