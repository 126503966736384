import React, {useContext, useEffect, useState} from "react";

import {Link} from "react-router-dom";

// Components.
import ActionButton from "../../../components/Buttons/ActionButton";
import Page from "../../../components/Page/Page";

// Contexts.
import {AppContext} from "../../../contexts/AppContext";

// Material Components.
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";

// Material Icons.
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ListIcon from '@mui/icons-material/List';
import VisibilityIcon from "@mui/icons-material/Visibility";

// Models.
import {areaToText, stateToText, typeToText} from "../../../models/challenges";

// Utils.
import {apiAdministrationInstance} from "../../../utils/api";
import {formatDateTime, relativeDateTime} from "../../../utils/date";

function List() {

    // Contexts.
    const appContext = useContext(AppContext);

    // States.
    const [challengeList, setChallengeList] = useState([]);

    // Load challengeList from API.
    useEffect(() => {

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        // Call API.
        apiAdministrationInstance.get("/challenges/all_challenges").then((response) => {

            setChallengeList(response.data.challenges ?? []);

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

        }).catch((error) => {

            // TODO: handle error.

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));
        });

    }, []);

    return (
        <Page title="Desafíos">
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{
                    marginBottom: 3,
                }}
                spacing={1}
            >
                <Grid
                    item
                >
                    <Button
                        color="secondary"
                        variant="outlined"
                        startIcon={<ListIcon/>}
                        component={Link}
                        to={"../updates/list"}
                    >
                        Actualizaciones
                    </Button>
                </Grid>

                <Grid
                    item
                >
                    <Button
                        variant="contained"
                        startIcon={<AddIcon/>}
                        component={Link}
                        to={"../new"}
                    >
                        Nuevo
                    </Button>
                </Grid>
            </Grid>

            {
                challengeList.length > 0 ?
                    <TableContainer>
                        <Table sx={{minWidth: 650}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell align="right">Tipo</TableCell>
                                    <TableCell align="right">Área</TableCell>
                                    <TableCell align="right">Última modificación</TableCell>
                                    <TableCell align="right">Estado</TableCell>
                                    <TableCell align="right">Acciones</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    challengeList.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.id}
                                            </TableCell>

                                            <TableCell align="right">
                                                {typeToText(row.type)}
                                            </TableCell>

                                            <TableCell align="right">
                                                {areaToText(row.area)}
                                            </TableCell>

                                            <TableCell align="right">
                                                <Tooltip
                                                    title={formatDateTime(row.last_modification_at)}>
                                                    <p>
                                                        {relativeDateTime(row.last_modification_at)}
                                                    </p>
                                                </Tooltip>
                                            </TableCell>

                                            <TableCell align="right">
                                                {stateToText(row.state)}
                                            </TableCell>

                                            <TableCell align="right">
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-end"
                                                    alignItems="flex-start"
                                                    spacing={1}
                                                >
                                                    <Grid
                                                        item
                                                    >
                                                        <ActionButton
                                                            color="primary"
                                                            description="Ver"
                                                            icon={<VisibilityIcon/>}
                                                            component={Link}
                                                            to={"../view/" + row.id}
                                                        />
                                                    </Grid>

                                                    <Grid
                                                        item
                                                    >
                                                        <ActionButton
                                                            color="secondary"
                                                            description="Editar"
                                                            icon={<EditIcon/>}
                                                            component={Link}
                                                            to={"../edit/" + row.id}
                                                        />
                                                    </Grid>

                                                    <Grid
                                                        item
                                                    >
                                                        <ActionButton
                                                            color="secondary"
                                                            description="Borrar"
                                                            icon={<DeleteIcon/>}
                                                            component={Link}
                                                            to={"../delete/" + row.id}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <></>
            }
        </Page>
    );
}

export default List;