import React, {useContext, useState} from "react";

import {useNavigate} from "react-router-dom";

import {useSnackbar} from "notistack";

// Components.
import Challenge from "../../../components/Challenge/Challenge";
import Page from "../../../components/Page/Page";

// Contexts.
import {AppContext} from "../../../contexts/AppContext";

// Material Components.
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

// Material Icons.
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

// Models.
import {defaultChallenge} from "../../../models/challenges";

// Utils.
import {apiAdministrationInstance} from "../../../utils/api";
import {capitalizeFirstLetter} from "../../../utils/strings";

function New() {

    // Contexts.
    const appContext = useContext(AppContext);

    // Navigate method.
    const navigate = useNavigate();

    // Snack.
    const {enqueueSnackbar} = useSnackbar();

    // Form inputs states.
    const [inputChallenge, setInputChallenge] = useState(defaultChallenge);

    // Clean form inputs.
    // Set form states to default values.
    const cleanInputs = () => {
        setInputChallenge(defaultChallenge);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        apiAdministrationInstance.post("/challenges/new", inputChallenge).then((response) => {

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

            enqueueSnackbar("¡" + capitalizeFirstLetter(response.data.message) + "!", {
                variant: "success",
            });

            // Redirect.
            navigate("../list");

        }).catch((error) => {

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

            if (error.response?.data?.message) {

                enqueueSnackbar("¡" + capitalizeFirstLetter(error.response.data.message) + "!", {
                    variant: "error",
                });

            } else {

                enqueueSnackbar("¡Error interno!", {
                    variant: "error",
                });
            }

        });
    }

    return (
        <Page title="Nuevo desafío">
            <Box
                component="form"
                autoComplete="off"
                onSubmit={handleSubmit}
            >
                <Challenge
                    state={inputChallenge}
                    setState={setInputChallenge}
                />

                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    sx={{
                        marginTop: 1,
                    }}
                >
                    <Grid
                        item
                    >
                        <Button
                            color="secondary"
                            variant="text"
                            startIcon={<DeleteIcon/>}
                            onClick={_ => cleanInputs()}
                        >
                            Limpiar campos
                        </Button>
                    </Grid>

                    <Grid
                        item
                    >
                        <Button
                            variant="contained"
                            type="submit"
                            startIcon={<SaveIcon/>}
                        >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Page>
    );
}

export default New;