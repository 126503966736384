import React, {useContext, useState} from "react";

import {useNavigate} from "react-router-dom";

import {useSnackbar} from "notistack";

// Components.
import Image from "../../../components/Image/Image";
import Page from "../../../components/Page/Page";

// Contexts.
import {AppContext} from "../../../contexts/AppContext";

// Material Components.
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

// Material Icons.
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

// Models.
import {
    defaultAchievement,
    rarityCommon,
    rarityLegend,
    rarityRare,
    rarityToText,
    recompenseCoin,
    recompenseExperience,
    recompenseItem,
    recompenseLive,
    recompenseToText,
    recompenseUnassigned
} from "../../../models/achievements";

// Utils.
import {apiAdministrationInstance} from "../../../utils/api";
import {capitalizeFirstLetter} from "../../../utils/strings";

function New() {

    // Contexts.
    const appContext = useContext(AppContext);

    // Navigate method.
    const navigate = useNavigate();

    // Snack.
    const {enqueueSnackbar} = useSnackbar();

    // Form inputs states.
    const [inputAchievement, setInputAchievement] = useState(defaultAchievement);
    const [inputImage, setInputImage] = useState(null);

    // Clean form inputs.
    // Set form states to default values.
    const cleanInputs = () => {
        setInputAchievement(defaultAchievement);
        setInputImage(null);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        // Define form data.
        const formData = new FormData();

        // Add file to form data.
        formData.append("image_file", inputImage);

        // File data.
        const jsonData = JSON.stringify(inputAchievement);

        formData.append("data", jsonData);

        // API Call.
        apiAdministrationInstance.post("/achievements/new", formData).then((response) => {

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

            // Clean all inputs.
            cleanInputs();

            enqueueSnackbar("¡" + capitalizeFirstLetter(response.data.message) + "!", {
                variant: "success",
            });

            // Redirect.
            navigate("../list");

        }).catch((error) => {

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

            if (error.response?.data?.message) {

                enqueueSnackbar("¡" + capitalizeFirstLetter(error.response.data.message) + "!", {
                    variant: "error",
                });

            } else {

                enqueueSnackbar("¡Error interno!", {
                    variant: "error",
                });
            }
        });
    }

    return (
        <Page title="Nuevo logro">
            <Box
                component="form"
                autoComplete="off"
                onSubmit={handleSubmit}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{
                        marginBottom: 2,
                    }}
                    spacing={3}
                >
                    <Grid
                        item
                    >
                        <Image
                            state={inputImage}
                            setState={setInputImage}
                            imageSize={271}
                        />
                    </Grid>

                    <Grid
                        item
                        xs
                    >
                        <FormControl
                            fullWidth
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            <TextField
                                required
                                id="input-title"
                                label="Título"
                                value={inputAchievement.title}
                                onChange={(event) => setInputAchievement(prevState => ({
                                    ...prevState,
                                    title: event.target.value,
                                }))}
                            />
                        </FormControl>

                        <FormControl
                            fullWidth
                            required
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            <InputLabel id="input-rarity-label">Rareza</InputLabel>
                            <Select
                                labelId="input-rarity-label"
                                id="input-rarity"
                                label="Rareza"
                                value={inputAchievement.rarity}
                                onChange={(event) => setInputAchievement(prevState => ({
                                    ...prevState,
                                    rarity: parseInt(event.target.value),
                                }))}
                            >
                                <MenuItem value={rarityCommon}>
                                    {rarityToText(rarityCommon)}
                                </MenuItem>

                                <MenuItem value={rarityRare}>
                                    {rarityToText(rarityRare)}
                                </MenuItem>

                                <MenuItem value={rarityLegend}>
                                    {rarityToText(rarityLegend)}
                                </MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl
                            fullWidth
                            required
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            <InputLabel id="input-recompense-label">Recompensa</InputLabel>

                            <Select
                                labelId="input-recompense-label"
                                id="input-recompense"
                                label="Recompensa"
                                value={inputAchievement.recompense}
                                onChange={(event) => setInputAchievement(prevState => ({
                                    ...prevState,
                                    recompense: parseInt(event.target.value),
                                }))}
                            >
                                <MenuItem value={recompenseUnassigned}>
                                    {recompenseToText(recompenseUnassigned)}
                                </MenuItem>

                                <MenuItem value={recompenseItem}>
                                    {recompenseToText(recompenseItem)}
                                </MenuItem>

                                <MenuItem value={recompenseLive}>
                                    {recompenseToText(recompenseLive)}
                                </MenuItem>

                                <MenuItem value={recompenseCoin}>
                                    {recompenseToText(recompenseCoin)}
                                </MenuItem>

                                <MenuItem value={recompenseExperience}>
                                    {recompenseToText(recompenseExperience)}
                                </MenuItem>
                            </Select>
                        </FormControl>

                        {
                            (inputAchievement.recompense === recompenseItem) ?
                                <FormControl
                                    fullWidth
                                >
                                    <TextField
                                        required
                                        id="input-recompense-id"
                                        label="ID de la recompensa"
                                        value={inputAchievement.recompense_id}
                                        onChange={(event) => setInputAchievement(prevState => ({
                                            ...prevState,
                                            recompense_id: event.target.value,
                                        }))}
                                    />
                                </FormControl>
                                :
                                <></>
                        }

                        {
                            (inputAchievement.recompense === recompenseLive || inputAchievement.recompense === recompenseCoin || inputAchievement.recompense === recompenseExperience) ?
                                <FormControl
                                    fullWidth
                                >
                                    <TextField
                                        required
                                        id="input-recompense-quantity"
                                        label="Cantidad de la recompensa"
                                        type="number"
                                        InputProps={{inputProps: {min: 1}}}
                                        value={inputAchievement.recompense_quantity || inputAchievement.recompense_quantity === 0 ? inputAchievement.recompense_quantity : ""}
                                        onChange={(event) => setInputAchievement(prevState => ({
                                            ...prevState,
                                            recompense_quantity: parseInt(event.target.value),
                                        }))}
                                    />
                                </FormControl>
                                :
                                <></>
                        }
                    </Grid>
                </Grid>

                <FormControl
                    fullWidth
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <TextField
                        id="input-description"
                        label="Descripción"
                        multiline
                        rows={4}
                        value={inputAchievement.description}
                        onChange={(event) => setInputAchievement(prevState => ({
                            ...prevState,
                            description: event.target.value,
                        }))}
                    />
                </FormControl>

                <FormControl
                    fullWidth
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <TextField
                        id="input-annotation"
                        label="Anotación"
                        multiline
                        rows={4}
                        value={inputAchievement.annotation}
                        onChange={(event) => setInputAchievement(prevState => ({
                            ...prevState,
                            annotation: event.target.value,
                        }))}
                    />
                </FormControl>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    sx={{
                        marginTop: 2,
                    }}
                >
                    <Grid
                        item
                    >
                        <Button
                            color="secondary"
                            variant="text"
                            startIcon={<DeleteIcon/>}
                            onClick={_ => cleanInputs()}
                        >
                            Limpiar campos
                        </Button>
                    </Grid>

                    <Grid
                        item
                    >
                        <Button
                            type="submit"
                            variant="contained"
                            startIcon={<SaveIcon/>}
                        >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Page>
    );
}

export default New;