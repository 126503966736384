import {DateTime} from "luxon";

export const showDateTime = (dateTime) => {
    return formatDateTime(dateTime) + " (" + relativeDateTime(dateTime) + ")";
}

export const relativeDateTime = (dateTime) => {
    return DateTime.fromISO(dateTime).toRelative();
}

export const formatDateTime = (dateTime) => {
    return DateTime.fromISO(dateTime).toFormat("dd/MM/yyyy H:mm:ss");
}

export const formatDate = (date) => {
    return DateTime.fromISO(date).toFormat("dd/MM/yyyy");
}