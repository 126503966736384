import React, {useContext, useEffect, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";

import {useSnackbar} from "notistack";

// Components.
import Page from "../../../components/Page/Page";
import PersonalizedMessage from "../../../components/PersonalizedMessage/PersonalizedMessage";

// Contexts.
import {AppContext} from "../../../contexts/AppContext";

// Material Components.
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

// Material Icons.
import DeleteIcon from "@mui/icons-material/Delete";

// Utils.
import {apiAdministrationInstance} from "../../../utils/api";
import {showDateTime} from "../../../utils/date";
import {capitalizeFirstLetter} from "../../../utils/strings";

function Delete() {

    // Contexts.
    const appContext = useContext(AppContext);

    // Navigate method.
    const navigate = useNavigate();

    // URL Params.
    const {sessionID} = useParams();

    // Snack.
    const {enqueueSnackbar} = useSnackbar();

    const [sessionData, setSessionData] = useState({});

    // Execute when change sessionID.
    // Obtains session data.
    useEffect(() => {

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        // Call API.
        apiAdministrationInstance.get("/sessions/get?sessionID=" + sessionID).then((response) => {

            setSessionData(response.data.session ?? {});

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

        }).catch((error) => {

            // TODO: handle error.

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));
        });

    }, [sessionID]);

    const handleDelete = () => {

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        apiAdministrationInstance.delete("/sessions/delete?sessionID=" + sessionID).then((response) => {

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

            enqueueSnackbar("¡" + capitalizeFirstLetter(response.data.message) + "!", {
                variant: "success",
            });

            // Redirect.
            navigate("../list");

        }).catch((error)=> {

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

            if (error.response?.data?.message) {

                enqueueSnackbar("¡" + capitalizeFirstLetter(error.response.data.message) + "!", {
                    variant: "error",
                });

            } else {

                enqueueSnackbar("¡Error interno!", {
                    variant: "error",
                });
            }
        });
    }

    return (
        <Page title="Borrar sesión">
            {
                sessionData.id ?
                    <>
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary="ID"
                                    secondary={sessionData.id}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="ID Usuario solicitante"
                                    secondary={sessionData.user_id}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Emitida el"
                                    secondary={sessionData.token_start_date ? showDateTime(sessionData.token_start_date) : "-"}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Expira el"
                                    secondary={sessionData.token_expire_date ? showDateTime(sessionData.token_expire_date) : "-"}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Estado"
                                    secondary={sessionData.state ? "Activa" : "Desactivada"}
                                />
                            </ListItem>
                        </List>

                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            sx={{
                                marginTop: 2,
                            }}
                        >
                            <Grid
                                item
                            >
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    startIcon={<DeleteIcon/>}
                                    onClick={_ => handleDelete()}
                                >
                                    Borrar
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                    :
                    (
                        !appContext.value.loading ?
                            <PersonalizedMessage
                                title="Aish!"
                                secondary="Sesión no encontrada 😔"
                                small
                            />
                            :
                            <></>
                    )
            }
        </Page>
    );
}

export default Delete;