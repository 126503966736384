import React, {useContext, useEffect, useState} from "react";

import {useSnackbar} from "notistack";

import FileDownload from "js-file-download";

// Components.
import ActionButton from "../../../components/Buttons/ActionButton";
import Page from "../../../components/Page/Page";

// Contexts.
import {AppContext} from "../../../contexts/AppContext";

// Material Components.
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Tooltip from "@mui/material/Tooltip";

// Material Icons.
import DownloadIcon from "@mui/icons-material/Download";

// Models.
import {typeToText} from "../../../models/files";

// Utils.
import {apiAdministrationInstance} from "../../../utils/api";
import {formatDateTime, relativeDateTime} from "../../../utils/date";
import {capitalizeFirstLetter} from "../../../utils/strings";

function List() {

    // Contexts.
    const appContext = useContext(AppContext);

    // Snack.
    const {enqueueSnackbar} = useSnackbar();

    // States.
    const [fileList, setFileList] = useState([]);

    // Load fileList from API.
    useEffect(() => {

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        // Call API.
        apiAdministrationInstance.get("/files/all_files").then((response) => {

            setFileList(response.data.files ?? []);

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

        }).catch((error) => {

            // TODO: handle error.

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));
        });

    }, []);

    // Handle download file button.
    const downloadFile = (event, fileID) => {
        event.preventDefault();

        // Call API.
        apiAdministrationInstance.get("/files/download/" + fileID, {
            responseType: "blob",
        }).then((response) => {

            // Get header.
            const contentHeader = response.headers["content-disposition"];

            // Obtains file name.
            const fileName = contentHeader.split("filename=")[1].replaceAll('"', '');

            FileDownload(response.data, fileName);

        }).catch((error) => {

            if (error.response?.data?.message) {

                enqueueSnackbar("¡" + capitalizeFirstLetter(error.response.data.message) + "!", {
                    variant: "error",
                });

            } else {

                enqueueSnackbar("¡Error interno!", {
                    variant: "error",
                });
            }
        });
    }

    return (
        <Page title="Archivos">
            {
                fileList.length > 0 ?
                    <TableContainer>
                        <Table sx={{minWidth: 650}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell align="right">Tipo</TableCell>
                                    <TableCell align="right">¿Ubicación Externa?</TableCell>
                                    <TableCell align="right">Subido por</TableCell>
                                    <TableCell align="right">Fecha subida</TableCell>
                                    <TableCell align="right">Acciones</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    fileList.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.id}
                                            </TableCell>

                                            <TableCell align="right">
                                                {typeToText(row.type)}
                                            </TableCell>

                                            <TableCell align="right">
                                                {row.location?.is_external ? "Sí" : "No"}
                                            </TableCell>

                                            <TableCell align="right">
                                                {row.submit_by}
                                            </TableCell>

                                            <TableCell align="right">
                                                <Tooltip
                                                    title={formatDateTime(row.submit_at)}
                                                >
                                                    <p>
                                                        {relativeDateTime(row.submit_at)}
                                                    </p>
                                                </Tooltip>
                                            </TableCell>

                                            <TableCell align="right">
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-end"
                                                    alignItems="flex-start"
                                                    spacing={1}
                                                >
                                                    <Grid
                                                        item
                                                    >
                                                        <ActionButton
                                                            color="primary"
                                                            description="Descargar"
                                                            icon={<DownloadIcon/>}
                                                            onClick={event => downloadFile(event, row.id)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <></>
            }
        </Page>
    );
}

export default List;