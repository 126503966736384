import React, {useEffect, useState} from "react";

// Components.
import Questions from "./Questions/Questions";

// Material Components.
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

// Models.
import {
    areaLanguage,
    areaMaths,
    areaToText,
    baseTextTypeLiterary,
    baseTextTypeMassiveMeansOfCommunication,
    baseTextTypeNonLiterary,
    baseTextTypeToText,
    baseTextTypeUnassigned,
    themeLanguage,
    themeMaths,
    typeDiagnostic,
    typeNormal,
    typeToText
} from "../../models/challenges";

// Utils.
import {formatParagraph} from "../../utils/strings";

/*
 * Two Props Need:
 * - props.state: state of challenges (defaultChallenge).
 * - props.setState: update state of challenges.
 */
function Challenge(props) {

    // Data states.
    const [themes, setThemes] = useState({
        0: "Cargando...",
        1: "Cargando...",
        2: "Cargando...",
        3: "Cargando...",
    });

    // Execute when user change selected area.
    useEffect(() => {
        switch (props.state.area) {
            case areaLanguage:
                // Change theme.
                setThemes(themeLanguage);

                break;

            case areaMaths:
                // TODO: Send alert to confirm.

                // Change theme.
                setThemes(themeMaths);

                // Clean base text, base image and truncate questions.
                props.setState(prevState => ({
                    ...prevState,
                    base_text_type: 0,
                    base_text: "",
                    base_image: {
                        ...prevState.base_image,
                        url: "",
                    },
                    questions: [prevState.questions[0],],
                }));

                break;

            default:
                // Change theme.
                // Dummy themes
                setThemes({
                    0: "Cargando...",
                    1: "Cargando...",
                    2: "Cargando...",
                    3: "Cargando...",
                });

                break;
        }
    }, [props.state.area]);

    return (
        <>
            <FormControl
                fullWidth
                required
                sx={{
                    marginBottom: 2
                }}
            >
                <InputLabel id="input-area-label">Tipo de Pregunta</InputLabel>
                <Select
                    labelId="input-area-label"
                    id="input-area"
                    label="Tipo de Pregunta"
                    value={props.state.type}
                    onChange={event => props.setState(prevState => ({
                        ...prevState,
                        type: parseInt(event.target.value),
                    }))}
                >
                    <MenuItem value={typeNormal}>{typeToText(typeNormal)}</MenuItem>
                    <MenuItem value={typeDiagnostic}>{typeToText(typeDiagnostic)}</MenuItem>
                </Select>
            </FormControl>

            <FormControl
                fullWidth
                required
                sx={{
                    marginBottom: 2
                }}
            >
                <InputLabel id="input-area-label">Área</InputLabel>
                <Select
                    labelId="input-area-label"
                    id="input-area"
                    label="Área"
                    value={props.state.area}
                    onChange={event => props.setState(prevState => ({
                        ...prevState,
                        area: parseInt(event.target.value),
                    }))}
                >
                    <MenuItem value={areaLanguage}>{areaToText(areaLanguage)}</MenuItem>
                    <MenuItem value={areaMaths}>{areaToText(areaMaths)}</MenuItem>
                </Select>
            </FormControl>

            {
                // If selected area == "Lenguaje"
                props.state.area === areaLanguage ?
                    <>
                        <FormControl
                            fullWidth
                            required
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            <InputLabel id="input-base-text-type-label">Tipo de Texto Base</InputLabel>
                            <Select
                                labelId="input-base-text-type-label"
                                id="input-base-text-type"
                                label="Tipo de Texto Base"
                                value={props.state.base_text_type}
                                onChange={event => props.setState(prevState => ({
                                    ...prevState,
                                    base_text_type: parseInt(event.target.value),
                                }))}
                            >
                                <MenuItem
                                    value={baseTextTypeUnassigned}>{baseTextTypeToText(baseTextTypeUnassigned)}</MenuItem>
                                <MenuItem
                                    value={baseTextTypeLiterary}>{baseTextTypeToText(baseTextTypeLiterary)}</MenuItem>
                                <MenuItem
                                    value={baseTextTypeNonLiterary}>{baseTextTypeToText(baseTextTypeNonLiterary)}</MenuItem>
                                <MenuItem
                                    value={baseTextTypeMassiveMeansOfCommunication}>{baseTextTypeToText(baseTextTypeMassiveMeansOfCommunication)}</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl
                            fullWidth
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            <TextField
                                id="input-base-text"
                                label="Texto Base"
                                multiline
                                rows={8}
                                value={props.state.base_text}
                                onChange={event => props.setState(prevState => ({
                                    ...prevState,
                                    base_text: event.target.value,
                                }))}
                            />

                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-start"
                                sx={{
                                    marginTop: 1
                                }}
                            >
                                <Grid
                                    item
                                >
                                    <Button
                                        color="secondary"
                                        variant="text"
                                        onClick={_ => props.setState(prevState => ({
                                            ...prevState,
                                            base_text: formatParagraph(prevState.base_text),
                                        }))}
                                    >
                                        Limpiar formato
                                    </Button>
                                </Grid>
                            </Grid>
                        </FormControl>

                        <FormControl
                            fullWidth
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            <TextField
                                id="input-base-image"
                                label="URL de la Imagen Base"
                                value={props.state.base_image.url}
                                onChange={event => props.setState(prevState => ({
                                    ...prevState,
                                    base_image: {
                                        ...prevState.base_image,
                                        url: event.target.value,
                                    },
                                }))}
                            />
                        </FormControl>
                    </>
                    :
                    <></>
            }

            <FormControl
                fullWidth
                sx={{
                    marginBottom: 5
                }}
            >
                <TextField
                    id="input-source"
                    label="Fuente"
                    value={props.state.source}
                    onChange={event => props.setState(prevState => ({
                        ...prevState,
                        source: event.target.value,
                    }))}
                />
            </FormControl>

            <Questions
                state={props.state}
                setState={props.setState}
                themes={themes}
            />
        </>
    );
}

export default Challenge;