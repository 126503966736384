import React, {useState} from "react";

import {BrowserRouter, Navigate, Routes, Route} from "react-router-dom";

// Components.
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";

// Contexts.
import {AppContext, defaultAppContext} from "./contexts/AppContext";
import {UserContext, defaultUserContext} from "./contexts/UserContext";

// Pages.
import AchievementsDelete from "./pages/Dashboard/Achievements/Delete";
import AchievementsEdit from "./pages/Dashboard/Achievements/Edit";
import AchievementsList from "./pages/Dashboard/Achievements/List";
import AchievementsNew from "./pages/Dashboard/Achievements/New";
import AchievementsView from "./pages/Dashboard/Achievements/View";
import ActionsDelete from "./pages/Dashboard/Actions/Delete";
import ActionsList from "./pages/Dashboard/Actions/List";
import ActionsNew from "./pages/Dashboard/Actions/New";
import ActionsView from "./pages/Dashboard/Actions/View";
import ChallengesDelete from "./pages/Dashboard/Challenges/Delete";
import ChallengesEdit from "./pages/Dashboard/Challenges/Edit";
import ChallengesList from "./pages/Dashboard/Challenges/List";
import ChallengesNew from "./pages/Dashboard/Challenges/New";
import ChallengesView from "./pages/Dashboard/Challenges/View";
import ChallengesUpdatesList from "./pages/Dashboard/Challenges/Updates/List";
import ChallengesUpdatesReview from "./pages/Dashboard/Challenges/Updates/Review";
import Dashboard from "./pages/Dashboard/Dashboard";
import DocumentsDelete from "./pages/Dashboard/Documents/Delete";
import DocumentsList from "./pages/Dashboard/Documents/List";
import DocumentsNew from "./pages/Dashboard/Documents/New";
import FilesList from "./pages/Dashboard/Files/List";
import Home from "./pages/Dashboard/Home/Home";
import ItemsDelete from "./pages/Dashboard/Items/Delete";
import ItemsList from "./pages/Dashboard/Items/List";
import ItemsNew from "./pages/Dashboard/Items/New";
import ItemsView from "./pages/Dashboard/Items/View";
import MaterialApp from "./MaterialApp";
import NotFound from "./pages/NotFound/NotFound";
import SessionsDelete from "./pages/Dashboard/Sessions/Delete";
import SessionsList from "./pages/Dashboard/Sessions/List";
import SessionsView from "./pages/Dashboard/Sessions/View";
import Unauthorized from "./pages/Unauthorized/Unauthorized";
import UsersDelete from "./pages/Dashboard/Users/Delete";
import UsersEdit from "./pages/Dashboard/Users/Edit";
import UsersList from "./pages/Dashboard/Users/List";
import UsersNew from "./pages/Dashboard/Users/New";
import UsersView from "./pages/Dashboard/Users/View";

function App() {

    // Contexts.
    const [appContext, setAppContext] = useState(defaultAppContext);
    const [userContext, setUserContext] = useState(defaultUserContext);

    return (
        <AppContext.Provider
            value={{
                value: appContext,
                setValue: setAppContext
            }}
        >
            <UserContext.Provider
                value={{
                    value: userContext,
                    setValue: setUserContext
                }}
            >
                <BrowserRouter>
                    <Routes>
                        <Route element={<MaterialApp/>}>
                            <Route path="/" element={<PrivateRoute/>}>
                                <Route index element={<Navigate to="dashboard/home"/>}/>
                                <Route path="dashboard" element={<Dashboard/>}>
                                    <Route index element={<Navigate to="home"/>}/>
                                    <Route path="home" element={<Home/>}/>
                                    <Route path="achievements">
                                        <Route index element={<Navigate to="list"/>}/>
                                        <Route path="list" element={<AchievementsList/>}/>
                                        <Route path="new" element={<AchievementsNew/>}/>
                                        <Route path="delete/:achievementID" element={<AchievementsDelete/>}/>
                                        <Route path="edit/:achievementID" element={<AchievementsEdit/>}/>
                                        <Route path="view/:achievementID" element={<AchievementsView/>}/>
                                    </Route>
                                    <Route path="actions">
                                        <Route index element={<Navigate to="list"/>}/>
                                        <Route path="list" element={<ActionsList/>}/>
                                        <Route path="new" element={<ActionsNew/>}/>
                                        <Route path="delete/:actionID" element={<ActionsDelete/>}/>
                                        <Route path="view/:actionID" element={<ActionsView/>}/>
                                    </Route>
                                    <Route path="challenges">
                                        <Route index element={<Navigate to="list"/>}/>
                                        <Route path="list" element={<ChallengesList/>}/>
                                        <Route path="new" element={<ChallengesNew/>}/>
                                        <Route path="delete/:challengeID" element={<ChallengesDelete/>}/>
                                        <Route path="edit/:challengeID" element={<ChallengesEdit/>}/>
                                        <Route path="view/:challengeID" element={<ChallengesView/>}/>
                                        <Route path="updates">
                                            <Route index element={<Navigate to="list"/>}/>
                                            <Route path="list" element={<ChallengesUpdatesList/>}/>
                                            <Route path="review/:challengeID/:updateIndex"
                                                   element={<ChallengesUpdatesReview/>}/>
                                        </Route>
                                    </Route>
                                    <Route path="documents">
                                        <Route index element={<Navigate to="list"/>}/>
                                        <Route path="list" element={<DocumentsList/>}/>
                                        <Route path="new" element={<DocumentsNew/>}/>
                                        <Route path="delete/:documentID" element={<DocumentsDelete/>}/>
                                    </Route>
                                    <Route path="files">
                                        <Route index element={<Navigate to="list"/>}/>
                                        <Route path="list" element={<FilesList/>}/>
                                    </Route>
                                    <Route path="items">
                                        <Route index element={<Navigate to="list"/>}/>
                                        <Route path="list" element={<ItemsList/>}/>
                                        <Route path="new" element={<ItemsNew/>}/>
                                        <Route path="delete/:itemID" element={<ItemsDelete/>}/>
                                        <Route path="view/:itemID" element={<ItemsView/>}/>
                                    </Route>
                                    <Route path="sessions">
                                        <Route index element={<Navigate to="list"/>}/>
                                        <Route path="list" element={<SessionsList/>}/>
                                        <Route path="delete/:sessionID" element={<SessionsDelete/>}/>
                                        <Route path="view/:sessionID" element={<SessionsView/>}/>
                                    </Route>
                                    <Route path="users">
                                        <Route index element={<Navigate to="list"/>}/>
                                        <Route path="list" element={<UsersList/>}/>
                                        <Route path="new" element={<UsersNew/>}/>
                                        <Route path="delete/:userID" element={<UsersDelete/>}/>
                                        <Route path="edit/:userID" element={<UsersEdit/>}/>
                                        <Route path="view/:userID" element={<UsersView/>}/>
                                    </Route>
                                    <Route path="*" element={<NotFound/>}/>
                                </Route>
                            </Route>
                            <Route path="unauthorized" element={<Unauthorized/>}/>
                            <Route path="*" element={<NotFound/>}/>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </UserContext.Provider>
        </AppContext.Provider>
    );
}

export default App;