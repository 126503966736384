import React, {useContext, useEffect, useState} from "react";

import {Link} from "react-router-dom";

// Components.
import ActionButton from "../../../../components/Buttons/ActionButton";
import Page from "../../../../components/Page/Page";

// Contexts.
import {AppContext} from "../../../../contexts/AppContext";

// Material Components.
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Select from "@mui/material/Select";

// Material Icons.
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';

// Models.
import {stateAccepted, stateInReview, stateRejected, stateToText} from "../../../../models/challenges";

// Utils.
import {apiAdministrationInstance} from "../../../../utils/api";
import {formatDateTime, relativeDateTime} from "../../../../utils/date";

function List() {

    // Contexts.
    const appContext = useContext(AppContext);

    // States.
    const [updateList, setUpdateList] = useState([]);

    // Form states.
    const [inputState, setInputState] = useState(0);

    // Load challengeList from API.
    useEffect(() => {

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        // Call API.
        apiAdministrationInstance.get("/challenges/all_updates?state=" + inputState).then((response) => {

            setUpdateList(response.data.updates ?? []);

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

        }).catch((error) => {

            // TODO: handle error.

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));
        });

    }, [inputState]);

    return (
        <Page title="Solicitudes de actualización">
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{
                    marginBottom: 3,
                }}
            >
                <Grid
                    item
                >
                    <FormControl
                        sx={{
                            minWidth: 250,
                        }}
                    >
                        <InputLabel id="input-state-label">Estado</InputLabel>
                        <Select
                            labelId="input-state-label"
                            id="input-state"
                            label="Estado"
                            value={inputState}
                            onChange={(event) => setInputState(parseInt(event.target.value))}
                        >
                            <MenuItem value={stateInReview}>{stateToText(stateInReview)}</MenuItem>
                            <MenuItem value={stateAccepted}>{stateToText(stateAccepted)}</MenuItem>
                            <MenuItem value={stateRejected}>{stateToText(stateRejected)}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            {
                updateList.length > 0 ?
                    <TableContainer>
                        <Table sx={{minWidth: 650}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID desafío</TableCell>
                                    <TableCell align="right">Índice</TableCell>
                                    <TableCell align="right">Enviada</TableCell>
                                    <TableCell align="right">Estado</TableCell>
                                    <TableCell align="right">Acciones</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    updateList.map((row) => (
                                        <TableRow
                                            key={row.challenge_id}
                                            sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.challenge_id}
                                            </TableCell>

                                            <TableCell align="right">
                                                {row.update_index}
                                            </TableCell>

                                            <TableCell align="right">
                                                <Tooltip
                                                    title={formatDateTime(row.submit_at)}>
                                                    <p>
                                                        {relativeDateTime(row.submit_at)}
                                                    </p>
                                                </Tooltip>
                                            </TableCell>

                                            <TableCell align="right">
                                                {stateToText(row.state)}
                                            </TableCell>

                                            <TableCell align="right">
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-end"
                                                    alignItems="flex-start"
                                                    spacing={1}
                                                >
                                                    <Grid
                                                        item
                                                    >
                                                        <ActionButton
                                                            color="secondary"
                                                            description="Revisar"
                                                            icon={<ThumbsUpDownIcon/>}
                                                            component={Link}
                                                            to={"../review/" + row.challenge_id + "/" + row.update_index}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : <></>
            }
        </Page>
    );
}

export default List;