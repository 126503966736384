/*
 * ITEMS.
 */

// Types.
export const typeUnassigned = 0;
export const typeTitle = 1;
export const typeProfileFrame = 2;
export const typeProfilePicture = 3;
export const typeAvatar = 4;
export const typeAvatarVariant = 5;

export const typeToText = (type) => {
    switch (type) {
        case typeUnassigned:
            return "Sin asignar";

        case typeTitle:
            return "Título";

        case typeProfileFrame:
            return "Marco de perfil";

        case typeProfilePicture:
            return "Foto de perfil";

        case typeAvatar:
            return "Avatar";

        case typeAvatarVariant:
            return "Estilo de avatar";

        default:
            return "";
    }
}

// Rarities.
export const rarityCommon = 0;
export const rarityRare = 1;
export const rarityLegend = 2;

export const rarityToText = (rarity) => {
    switch (rarity) {
        case rarityCommon:
            return "Común";

        case rarityRare:
            return "Raro";

        case rarityLegend:
            return "Legendario";

        default:
            return "";
    }
}

// State.
export const stateActive = 0
export const stateDeactivated = 1

export const stateToText = (state) => {
    switch (state) {
        case stateActive:
            return "Activo";

        case stateDeactivated:
            return "Desactivado";

        default:
            return "";
    }
}

// Default Values.
export const defaultItem = {
    "title": "",
    "type": 0,
    "avatar_main_id": "",
    "rarity": 0,
    "is_merchantable": false,
    "price": 0,
    "annotation": "",
};