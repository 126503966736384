import React, {useContext, useEffect, useState} from "react";

import {Link, useParams} from "react-router-dom";

// Components.
import Page from "../../../components/Page/Page";
import PersonalizedMessage from "../../../components/PersonalizedMessage/PersonalizedMessage";

// Contexts.
import {AppContext} from "../../../contexts/AppContext";

// Material Components.
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

// Material Icons.
import DeleteIcon from "@mui/icons-material/Delete";

// Models.
import {rarityToText, stateToText, typeToText} from "../../../models/items";

// Utils.
import {apiAdministrationInstance} from "../../../utils/api";
import {showDateTime} from "../../../utils/date";

function View() {

    // Contexts.
    const appContext = useContext(AppContext);

    /// URL Params.
    const {itemID} = useParams();

    const [itemData, setItemData] = useState({});

    // Execute when change itemID.
    // Obtains item data.
    useEffect(() => {

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        // Call API.
        apiAdministrationInstance.get("/items/get?itemID=" + itemID).then((response) => {

            setItemData(response.data.item ?? {});

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

        }).catch((error) => {

            // TODO: handle error.

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));
        });

    }, [itemID]);

    return (
        <Page title="Ver ítem">
            {
                itemData.id ?
                    <>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            sx={{
                                marginBottom: 3,
                            }}
                            spacing={1}
                        >
                            <Grid
                                item
                            >
                                <Button
                                    color="secondary"
                                    variant="text"
                                    startIcon={<DeleteIcon/>}
                                    component={Link}
                                    to={"../delete/" + itemData.id}
                                >
                                    Borrar
                                </Button>
                            </Grid>
                        </Grid>

                        <List>
                            <ListItem>
                                <ListItemText
                                    primary="ID"
                                    secondary={itemData.id}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Título"
                                    secondary={itemData.title}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Tipo"
                                    secondary={typeToText(itemData.type)}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="ID avatar principal"
                                    secondary={itemData.avatar_main_id ??  "-"}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Rareza"
                                    secondary={rarityToText(itemData.rarity)}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="¿Comerciable?"
                                    secondary={itemData.is_merchantable ? "Sí" : "No"}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Precio"
                                    secondary={itemData.is_merchantable ? itemData.price : "-"}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Anotación"
                                    secondary={itemData.annotation ??  "-"}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Estado"
                                    secondary={stateToText(itemData.state)}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Subido por"
                                    secondary={itemData.submit_by}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Fecha de subida"
                                    secondary={itemData.submit_at ? showDateTime(itemData.submit_at) : "-"}
                                />
                            </ListItem>
                        </List>
                    </>
                    :
                    (
                        !appContext.value.loading ?
                            <PersonalizedMessage
                                title="Aish!"
                                secondary="Ítem no encontrado 😔"
                                small
                            />
                            :
                            <></>
                    )
            }
        </Page>
    );
}

export default View;