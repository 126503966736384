import React, {useContext, useEffect, useState} from "react";

import {Link, useParams} from "react-router-dom";

import {format} from "rut.js";

// Components.
import Page from "../../../components/Page/Page";
import PersonalizedMessage from "../../../components/PersonalizedMessage/PersonalizedMessage";

// Contexts.
import {AppContext} from "../../../contexts/AppContext";

// Material Components.
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

// Material Icons.
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

// Models.
import {genderToText, roleToText, stateToText} from "../../../models/users";

// Utils.
import {apiAdministrationInstance} from "../../../utils/api";
import {formatDate, showDateTime} from "../../../utils/date";

function View() {

    // Contexts.
    const appContext = useContext(AppContext);

    // URL Params.
    const {userID} = useParams();

    const [userData, setUserData] = useState({});

    // Execute when change userID.
    // Obtains user data.
    useEffect(() => {

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        // Call API.
        apiAdministrationInstance.get("/users/get?userID=" + userID).then((response) => {

            setUserData(response.data.user ?? {});

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

        }).catch((error) => {

            // TODO: handle error.

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));
        });

    }, [userID]);

    return (
        <Page title="Ver usuario">
            {
                userData.id ?
                    <>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            sx={{
                                marginBottom: 3,
                            }}
                            spacing={1}
                        >
                            <Grid
                                item
                            >
                                <Button
                                    color="primary"
                                    variant="text"
                                    startIcon={<EditIcon/>}
                                    component={Link}
                                    to={"../edit/" + userData.id}
                                >
                                    Editar
                                </Button>
                            </Grid>

                            <Grid
                                item
                            >
                                <Button
                                    color="secondary"
                                    variant="text"
                                    startIcon={<DeleteIcon/>}
                                    component={Link}
                                    to={"../delete/" + userData.id}
                                >
                                    Borrar
                                </Button>
                            </Grid>
                        </Grid>

                        <List>
                            <ListItem>
                                <ListItemText
                                    primary="ID"
                                    secondary={userData.id ?? "-"}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Nombre de usuario"
                                    secondary={userData.username ?? "-"}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Nombre"
                                    secondary={userData.name ?? "-"}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Apellidos"
                                    secondary={userData.last_name ?? "-"}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="RUT"
                                    secondary={format(userData.rut) ?? "-"}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Email"
                                    secondary={userData.email}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Celular"
                                    secondary={userData.phone ?? "-"}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Fecha de nacimiento"
                                    secondary={userData.birthday ? formatDate(userData.birthday) : "-"}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Género"
                                    secondary={genderToText(userData.gender)}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Rol"
                                    secondary={roleToText(userData.role)}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Estado"
                                    secondary={stateToText(userData.state)}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Registrado por"
                                    secondary={userData.registered_by ?? "-"}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Registrado"
                                    secondary={userData.registered_at ? showDateTime(userData.registered_at) : "-"}
                                />
                            </ListItem>
                        </List>
                    </>
                    :
                    (
                        !appContext.value.loading ?
                            <PersonalizedMessage
                                title="Aish!"
                                secondary="Usuario no encontrado 😔"
                                small
                            />
                            :
                            <></>
                    )

            }
        </Page>
    );
}

export default View;