import React, {useContext, useEffect, useState} from "react";

import {Link} from "react-router-dom";

// Components.
import ActionButton from "../../../components/Buttons/ActionButton";
import Page from "../../../components/Page/Page";

// Contexts.
import {AppContext} from "../../../contexts/AppContext";

// Material Components.
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// Material Icons.
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";

// Models.
import {actionToText, elementToText, stateToText, whenToText} from "../../../models/actions";

// Utils.
import {apiAdministrationInstance} from "../../../utils/api";

function List() {

    // Contexts.
    const appContext = useContext(AppContext);

    // States.
    const [actionList, setActionList] = useState([]);

    // Load actionList from API.
    useEffect(() => {

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        // Call API.
        apiAdministrationInstance.get("/actions/all_actions").then((response) => {

            setActionList(response.data.actions ?? []);

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

        }).catch((error) => {

            // TODO: handle error.

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));
        });

    }, []);

    return (
        <Page title="Acciones">
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{
                    marginBottom: 3,
                }}
            >
                <Grid
                    item
                >
                    <Button
                        variant="contained"
                        startIcon={<AddIcon/>}
                        component={Link}
                        to={"../new"}
                    >
                        Nueva
                    </Button>
                </Grid>
            </Grid>

            {
                actionList.length > 0 ?
                    <TableContainer>
                        <Table sx={{minWidth: 650}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>

                                    <TableCell align="right">¿Cuándo ejecutar?</TableCell>

                                    <TableCell align="right">Valor de X</TableCell>

                                    <TableCell align="right">Acción</TableCell>

                                    <TableCell align="right">Elemento</TableCell>

                                    <TableCell align="right">Estado</TableCell>

                                    <TableCell align="right">Acciones</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    actionList.map((row, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.id}
                                            </TableCell>

                                            <TableCell align="right">
                                                {whenToText(row.when)}
                                            </TableCell>

                                            <TableCell align="right">
                                                {row.when_x_value}
                                            </TableCell>

                                            <TableCell align="right">
                                                {actionToText(row.action)}
                                            </TableCell>

                                            <TableCell align="right">
                                                {elementToText(row.element)}
                                            </TableCell>

                                            <TableCell align="right">
                                                {stateToText(row.state)}
                                            </TableCell>

                                            <TableCell align="right">
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-end"
                                                    alignItems="flex-start"
                                                    spacing={1}
                                                >
                                                    <Grid
                                                        item
                                                    >
                                                        <ActionButton
                                                            color="primary"
                                                            description="Ver"
                                                            icon={<VisibilityIcon/>}
                                                            component={Link}
                                                            to={"../view/" + row.id}
                                                        />
                                                    </Grid>

                                                    <Grid
                                                        item
                                                    >
                                                        <ActionButton
                                                            color="secondary"
                                                            description="Borrar"
                                                            icon={<DeleteIcon/>}
                                                            component={Link}
                                                            to={"../delete/" + row.id}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <></>
            }
        </Page>
    );
}

export default List;