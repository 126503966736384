import React, {useContext, useEffect, useState} from "react";

import {Link} from "react-router-dom";

// Components.
import ActionButton from "../../../components/Buttons/ActionButton";
import Page from "../../../components/Page/Page";

// Contexts.
import {AppContext} from "../../../contexts/AppContext";

// Material Components.
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// Material Icons.
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";

// Models.
import {rarityToText, stateToText} from "../../../models/achievements";

// Utils.
import {apiAdministrationInstance} from "../../../utils/api";
import Tooltip from "@mui/material/Tooltip";
import {formatDateTime, relativeDateTime} from "../../../utils/date";

function List() {

    // Contexts.
    const appContext = useContext(AppContext);

    // States.
    const [achievementList, setAchievementList] = useState([]);

    // Load achievementList from API.
    useEffect(() => {

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        // Call API.
        apiAdministrationInstance.get("/achievements/all_achievements").then((response) => {

            setAchievementList(response.data.achievements ?? []);

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

        }).catch((error) => {

            // TODO: handle error.

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));
        });

    }, []);

    return (
        <Page title="Logros">
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{
                    marginBottom: 3,
                }}
            >
                <Grid
                    item
                >
                    <Button
                        variant="contained"
                        startIcon={<AddIcon/>}
                        component={Link}
                        to={"../new"}
                    >
                        Nuevo
                    </Button>
                </Grid>
            </Grid>

            {
                achievementList.length > 0 ?
                    <TableContainer>
                        <Table sx={{minWidth: 650}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell align="right">Titulo</TableCell>
                                    <TableCell align="right">Rareza</TableCell>
                                    <TableCell align="right">Última modificación</TableCell>
                                    <TableCell align="right">Estado</TableCell>
                                    <TableCell align="right">Acciones</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    achievementList.map((row, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.id}
                                            </TableCell>

                                            <TableCell align="right">
                                                {row.title}
                                            </TableCell>

                                            <TableCell align="right">
                                                {rarityToText(row.rarity)}
                                            </TableCell>

                                            <TableCell align="right">
                                                {
                                                    row.last_modification_at ?
                                                    <Tooltip
                                                        title={formatDateTime(row.last_modification_at)}>
                                                        <p>
                                                            {relativeDateTime(row.last_modification_at)}
                                                        </p>
                                                    </Tooltip>
                                                    :
                                                    "-"
                                                }
                                            </TableCell>

                                            <TableCell align="right">
                                                {stateToText(row.state)}
                                            </TableCell>

                                            <TableCell align="right">
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-end"
                                                    alignItems="flex-start"
                                                    spacing={1}
                                                >
                                                    <Grid
                                                        item
                                                    >
                                                        <ActionButton
                                                            color="primary"
                                                            description="Ver"
                                                            icon={<VisibilityIcon/>}
                                                            component={Link}
                                                            to={"../view/" + row.id}
                                                        />
                                                    </Grid>

                                                    <Grid
                                                        item
                                                    >
                                                        <ActionButton
                                                            color="secondary"
                                                            description="Editar"
                                                            icon={<EditIcon/>}
                                                            component={Link}
                                                            to={"../edit/" + row.id}
                                                        />
                                                    </Grid>

                                                    <Grid
                                                        item
                                                    >
                                                        <ActionButton
                                                            color="secondary"
                                                            description="Borrar"
                                                            icon={<DeleteIcon/>}
                                                            component={Link}
                                                            to={"../delete/" + row.id}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <></>
            }
        </Page>
    );
}

export default List;