import React, {useContext, useEffect, useState} from "react";

import {Link} from "react-router-dom";

import {useSnackbar} from "notistack";

import FileDownload from "js-file-download";

// Components.
import ActionButton from "../../../components/Buttons/ActionButton";
import Page from "../../../components/Page/Page";

// Contexts.
import {AppContext} from "../../../contexts/AppContext";

// Material Components.
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Tooltip from "@mui/material/Tooltip";

// Material Icons.
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";

// Utils.
import {apiAdministrationInstance} from "../../../utils/api";
import {formatDateTime, relativeDateTime} from "../../../utils/date";
import {capitalizeFirstLetter} from "../../../utils/strings";

function List() {

    // Contexts.
    const appContext = useContext(AppContext);

    // Snack.
    const {enqueueSnackbar} = useSnackbar();

    // States.
    const [documentList, setDocumentList] = useState([]);

    // Load documentList from API.
    useEffect(() => {

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        // Call API.
        apiAdministrationInstance.get("/documents/all_documents").then((response) => {

            setDocumentList(response.data.documents ?? []);

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

        }).catch((error) => {

            // TODO: handle error.

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));
        });

    }, []);

    // Handle download document button.
    const downloadDocument = (event, documentID) => {
        event.preventDefault();

        // Call API.
        apiAdministrationInstance.get("/documents/download/" + documentID, {
            responseType: "blob",
        }).then((response) => {

            // Get header.
            const contentHeader = response.headers["content-disposition"];

            // Obtains file name.
            const fileName = contentHeader.split("filename=")[1].replaceAll('"', '');

            FileDownload(response.data, fileName);

        }).catch((error) => {

            if (error.response?.data?.message) {

                enqueueSnackbar("¡" + capitalizeFirstLetter(error.response.data.message) + "!", {
                    variant: "error",
                });

            } else {

                enqueueSnackbar("¡Error interno!", {
                    variant: "error",
                });
            }
        });
    }

    return (
        <Page title="Documentos">
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{
                    marginBottom: 3,
                }}
            >
                <Grid
                    item
                >
                    <Button
                        variant="contained"
                        startIcon={<AddIcon/>}
                        component={Link}
                        to={"../new"}
                    >
                        Nuevo
                    </Button>
                </Grid>
            </Grid>

            {
                documentList.length > 0 ?
                    <TableContainer>
                        <Table sx={{minWidth: 650}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell align="right">Título</TableCell>
                                    <TableCell align="right">Descripción</TableCell>
                                    <TableCell align="right">Fecha de subida</TableCell>
                                    <TableCell align="right">Acciones</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    documentList.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.id}
                                            </TableCell>

                                            <TableCell align="right">
                                                {row.title}
                                            </TableCell>

                                            <TableCell align="right">
                                                {row.description}
                                            </TableCell>

                                            <TableCell align="right">
                                                <Tooltip
                                                    title={formatDateTime(row.submit_at)}
                                                >
                                                    <p>
                                                        {relativeDateTime(row.submit_at)}
                                                    </p>
                                                </Tooltip>
                                            </TableCell>

                                            <TableCell align="right">
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-end"
                                                    alignItems="flex-start"
                                                    spacing={1}
                                                >
                                                    <Grid
                                                        item
                                                    >
                                                        <ActionButton
                                                            color="primary"
                                                            description="Descargar"
                                                            icon={<DownloadIcon/>}
                                                            onClick={event => downloadDocument(event, row.id)}
                                                        />
                                                    </Grid>

                                                    <Grid
                                                        item
                                                    >
                                                        <ActionButton
                                                            color="secondary"
                                                            description="Borrar"
                                                            icon={<DeleteIcon/>}
                                                            component={Link}
                                                            to={"../delete/" + row.id}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <></>
            }
        </Page>
    );
}

export default List;